import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '@lib/contexts/user-context'
import { getShortDateString } from '@lib/date-utils'
import { fetchApiData } from '@lib/fetch-api-data'
import { API_URL } from '@lib/constants'
import { getErrorMessage } from '@lib/get-error-message'
import { Alert } from '@components/Alert'
import { FieldSet } from '@components/FieldSet'
import { Field } from '@components/Field'
import { Button } from '@components/Button'
import { convertSpecialCharacters } from '@lib/convert-special-characters'
import { trackEvent } from '@lib/tracking'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { ClassNames, DayPicker } from 'react-day-picker'
import datePickerStyles from 'react-day-picker/dist/style.module.css'

import styles from './CreateGalleryForm.module.css'

const AUTH_CHECK_ENDPOINT = `${API_URL}/auth/check`

type Host = {
  firstName: string
  surname: string
  email: string
}

type Inputs = {
  host1: Host
  host2: Host
  eventDate: Date
  gallerySlug: string
  galleryName: string
}

export const CreateGalleryForm = () => {
  const navigate = useNavigate()
  const { updateUser, user } = useUserContext()
  // TODO: handle form validation errors
  const startDate = new Date()
  const [isChecking, setIsChecking] = useState(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const isAuthed = user?.isAuthenticated
  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      host1: {
        firstName: user?.firstName || undefined,
        surname: user?.surname || undefined,
        email: user?.email || undefined,
      },
      eventDate: startDate,
      galleryName: '',
    },
  })

  const checkAuthStatus = async () => {
    setIsChecking(true)
    try {
      const { id, firstName, surname, currentGallery, email, preferences } =
        await fetchApiData(AUTH_CHECK_ENDPOINT)
      updateUser({
        id,
        email,
        firstName,
        surname,
        currentGallery,
        isAuthenticated: true,
        preferences,
      })
      setValue('host1.firstName', firstName)
      setValue('host1.surname', surname)
      setValue('host1.email', email)
    } catch (err) {
    } finally {
      setIsChecking(false)
    }
  }

  useEffectOnce(() => {
    trackEvent('Gallery Creation page view', {})
    if (!isAuthed) {
      checkAuthStatus()
    }
  })

  const host1FirstName = watch('host1.firstName')
  const host2FirstName = watch('host2.firstName')
  const [defaultGalleryName, setDefaultGalleryName] = useState<string>('')

  useEffect(() => {
    if (host1FirstName && host2FirstName) {
      setValue(
        'gallerySlug',
        `${convertSpecialCharacters(
          host1FirstName.toLowerCase()
        )}-and-${convertSpecialCharacters(host2FirstName.toLowerCase())}`
      )
    }
  }, [host1FirstName, host2FirstName, setValue])

  useEffect(() => {
    if (host1FirstName && host2FirstName) {
      setDefaultGalleryName(`${host1FirstName} and ${host2FirstName}`)
    }
  }, [host1FirstName, host2FirstName])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsSaving(true)
    // the data here should be free of errors
    const { host1, host2, gallerySlug, galleryName, eventDate } = data
    // transform the data into server friendly structure
    const hosts = [host1, host2]
    const payload = {
      hosts,
      eventDate: getShortDateString(eventDate),
      slug: gallerySlug,
      name: galleryName.length > 0 ? galleryName : defaultGalleryName,
    }

    // send it
    try {
      const { gallery } = await fetchApiData(`${API_URL}/galleries`, {
        method: 'POST',
        data: payload,
      })

      if (isAuthed) {
        updateUser({ currentGallery: gallery, isCreatingGallery: true })
        navigate(`/gallery/payment`)
      } else {
        updateUser({
          email: host1.email,
          currentGallery: gallery,
          isCreatingGallery: true,
        })
        navigate(`/auth/confirm`)
      }
    } catch (err) {
      setError(getErrorMessage(err))
      setIsSaving(false)
    }
  }

  if (isChecking) {
    return <LoadingSpinner />
  }

  const datePickerClassNames: ClassNames = {
    ...datePickerStyles,
  }

  return (
    <form className="new-gallery-page__form" onSubmit={handleSubmit(onSubmit)}>
      {error && <Alert message={error} />}
      {errors.host1?.email?.type === 'validate' &&
        errors.host2?.email?.type === 'validate' && (
          <Alert message="Host emails cannot be the same" />
        )}
      <div className={styles.panel}>
        <FieldSet legendLabel="Your details" name="person1">
          <div className="row">
            <Field
              inputId="person1FirstName"
              inputType="text"
              label="First name"
              {...register('host1.firstName', { required: true })}
              disabled={!!user}
            />
            <Field
              inputId="person1Surname"
              inputType="text"
              label="Surname"
              {...register('host1.surname', { required: true })}
              disabled={!!user}
            />
          </div>
          <div className="row">
            <Field
              inputId="person1EmailAddress"
              inputType="email"
              label="Email address"
              {...register('host1.email', {
                required: true,
                validate: (value) =>
                  (value.length > 0 && value !== getValues('host2.email')) ||
                  'Host emails should not match',
              })}
              disabled={!!user}
            />
          </div>
        </FieldSet>
        <FieldSet legendLabel="Engaged to" name="person2">
          <div className="row">
            <Field
              inputId="person2FirstName"
              inputType="text"
              label="First name"
              errorMessage={
                errors.host2?.firstName && 'Please enter first name'
              }
              {...register('host2.firstName', { required: true })}
            />
            <Field
              inputId="person2Surname"
              inputType="text"
              label="Surname"
              errorMessage={errors.host2?.surname && 'Please enter surname'}
              {...register('host2.surname', { required: true })}
            />
          </div>
          <div className="row">
            <Field
              inputId="person2EmailAddress"
              inputType="email"
              label="Email address"
              additionalInfo="We'll send an invite when gallery is setup"
              errorMessage={
                errors.host2?.email && 'Please enter a valid email address'
              }
              {...register('host2.email', {
                required: true,
                validate: (value) =>
                  (value.length > 0 && value !== getValues('host1.email')) ||
                  'Host emails should not match',
              })}
            />
          </div>
        </FieldSet>
      </div>
      <div className={styles.panel}>
        <Field
          inputId="galleryName"
          inputType="text"
          label="The name of your gallery"
          placeholder={defaultGalleryName}
          additionalInfo="This will usually be a combination of your first names"
          {...(register('galleryName'),
          {
            required: false,
          })}
        />
      </div>
      <div className={styles.panel}>
        <FieldSet legendLabel="Wedding date" name="gettingMarriedOn">
          <div className={styles.datePicker}>
            <Controller
              control={control}
              name="eventDate"
              render={({ field }) => (
                <DayPicker
                  mode="single"
                  selected={field.value}
                  classNames={datePickerClassNames}
                  styles={{
                    caption: {
                      color: 'var(--color-independence)',
                      fontWeight: 'normal',
                    },
                  }}
                  modifiersStyles={{
                    selected: {
                      backgroundColor: 'var(--color-independence)',
                    },
                  }}
                  onSelect={(date: Date | undefined) => {
                    field.onChange(date)
                  }}
                />
              )}
            />
          </div>
        </FieldSet>
      </div>
      <Field
        inputId="gallerySlug"
        inputType="hidden"
        label="Gallery Slug"
        {...register('gallerySlug', { required: true })}
      />
      <Button
        label="Create Your Gallery"
        actionLabel="Creating"
        isLoading={isSaving}
      />
    </form>
  )
}
