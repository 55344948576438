import styles from './FieldSet.module.css'

export interface FieldSetProps {
  legendLabel: string
  name: string
}
export const FieldSet: React.FC<React.PropsWithChildren<FieldSetProps>> = ({
  legendLabel,
  name,
  children,
}) => {
  return (
    <fieldset name={name}>
      <legend className={styles.legend}>{legendLabel}</legend>
      <div className={styles.fields}>{children}</div>
    </fieldset>
  )
}
