import { useParams } from 'react-router-dom'
import classNames from 'classnames'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { UploadButton } from '@components/UploadButton'
import { GalleryNameAndImage } from '@components/GalleryNameAndImage'
import { SocialMessage } from '@components/SocialMessage'
import { useScrolledUp } from '@hooks/useScrolledUp'

import styles from './MetaData.module.css'
import useMediaQuery from '@hooks/useMediaQuery'

interface MetaDataProps {
  showMessage?: boolean
}

export const MetaData = ({ showMessage }: MetaDataProps) => {
  const { galleryId } = useParams()
  const { gallery } = useGalleryMetadata(galleryId as string)

  return (
    <>
      <div className={classNames(styles.container)}>
        <GalleryNameAndImage
          name={gallery?.name || ''}
          eventDate={gallery?.eventDate || new Date()}
          imageUrl={gallery?.photoUrl || ''}
        />
        {useMediaQuery('(min-width: 720px)') && (
          <div>
            <UploadButton />
          </div>
        )}
      </div>
      {gallery?.message && showMessage && (
        <p className={styles.message}>{gallery.message}</p>
      )}
      {!gallery?.socialShareAllowed && <SocialMessage />}
    </>
  )
}
