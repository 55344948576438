import { fetchApiData } from '@lib/fetch-api-data'
import styles from './DeleteAlbumButton.module.css'
import { API_URL } from '@lib/constants'
import { useState } from 'react'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { TrashIcon } from '@heroicons/react/24/outline'

interface DeleteAlbumProps {
  galleryId: string
  albumId: string
  onDeleted: () => void
}

export const DeleteAlbumButton = ({
  galleryId,
  albumId,
  onDeleted,
}: DeleteAlbumProps) => {
  const [isDeleting, setIsDeleting] = useState(false)
  const handleDelete = async () => {
    setIsDeleting(true)
    const DELETE_MEDIA_ENDPOINT = `${API_URL}/galleries/${galleryId}/albums/${albumId}`
    try {
      await fetchApiData(DELETE_MEDIA_ENDPOINT, {
        method: 'DELETE',
      })
      setIsDeleting(false)
      onDeleted()
    } catch (err) {
      console.error('Album was not deleted')
      setIsDeleting(false)
    }
  }

  return (
    <>
      {isDeleting ? (
        <>
          <LoadingSpinner small color="#999" />
        </>
      ) : (
        <>
          <button
            className={styles.button}
            onClick={handleDelete}
            title="Delete"
            type="button"
          >
            <TrashIcon
              width={20}
              height={20}
              className="colorize-independence"
            />
          </button>
        </>
      )}
    </>
  )
}
