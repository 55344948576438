import { useParams } from 'react-router-dom'
import { useUserContext } from '@lib/contexts/user-context'
import { cloudinaryUploadWidget } from '@lib/cloudinary-upload-widget'
import { useCallback, useEffect, useState } from 'react'
import { API_URL } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { trackEvent } from '@lib/tracking'
import { CameraIcon } from '@heroicons/react/24/outline'

import styles from './UploadButton.module.css'
import footerStyles from '../GalleryFooter/FooterNav.module.css'
import { useMediaList } from '@data/useMediaList'
import { useGalleryParamsContext } from '@lib/contexts/gallery-params-context'

interface UploadProps {
  isFooter?: boolean
}

interface CloudinaryWidget {
  open: () => void
  destroy: () => void
}

export const UploadButton = ({ isFooter = false }: UploadProps) => {
  const { galleryId } = useParams()
  const { user } = useUserContext()
  const [widget, setWidget] = useState<CloudinaryWidget | null>()
  const { sort, filter, orderBy, album } = useGalleryParamsContext()
  const { mutate } = useMediaList(
    galleryId as string,
    sort,
    filter,
    orderBy,
    album
  )

  useEffect(() => {
    if (!widget) {
      const newWidget = cloudinaryUploadWidget({
        folder: galleryId as string,
        context: `gid=${galleryId} | uid=${user?.id}`,
        callback: saveSuccessfulMediaUpload,
        mutateCallback: () => {
          mutate()
        },
      })
      setWidget(newWidget)
    }
    return () => {
      if (widget) {
        widget.destroy()
        setWidget(null)
      }
    }
  }, [widget])

  const saveSuccessfulMediaUpload = useCallback(async (data: any) => {
    try {
      const mediaId = data.public_id.split('/')[1]
      const API_UPLOAD_ENDPOINT = `${API_URL}/media/upload`
      await fetchApiData(API_UPLOAD_ENDPOINT, {
        method: 'POST',
        data: { mediaId, uploadJSON: data },
      })
    } catch (err) {
      console.log(err)
    }
  }, [])

  const handleUploadClick = () => {
    widget && widget.open()
    if (galleryId) {
      trackEvent('Upload Button clicked', {
        gallery_id: galleryId,
      })
    }
  }

  return isFooter ? (
    <button type="button" onClick={handleUploadClick}>
      <span className={footerStyles.icon}>
        <CameraIcon width="22" height="22" />
      </span>
      Upload
    </button>
  ) : (
    <button type="button" onClick={handleUploadClick} className={styles.button}>
      <span className="label">Upload</span>
      <CameraIcon width="18" height="18" />
    </button>
  )
}
