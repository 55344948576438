import { Alert } from '@components/Alert'
import { Button } from '@components/Button'
import { Field } from '@components/Field'
import { Heading } from '@components/Heading'
import { API_URL, PATHS, VALID_EMAIL_REGEX } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { getErrorMessage } from '@lib/get-error-message'
import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from '@hooks/useEffectOnce'
import styles from './Login.module.css'
import { LegalLinks } from '@components/LegalLinks'

type Inputs = {
  emailAddress: string
}

const LOGIN_ENDPOINT = `${API_URL}/auth/login`

const Login = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const ac = searchParams.get('ac')
  const { updateUser } = useUserContext()
  const {
    register,
    handleSubmit,
    formState: { isValid, errors: formErrors },
  } = useForm<Inputs>({ mode: 'onChange' })
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  useEffectOnce(() => {
    document.title = 'Sharetheirday - Login'
  })

  const onSubmit: SubmitHandler<Inputs> = async ({ emailAddress }) => {
    setIsLoading(true)
    try {
      const { email } = await fetchApiData(LOGIN_ENDPOINT, {
        method: 'POST',
        data: { email: emailAddress },
      })
      updateUser({ email })
      const search = ac ? `?ac=${ac}` : undefined
      navigate(
        {
          pathname: PATHS.LOGIN_CONFIRM,
          search,
        },
        { state: { originalPath: location.state?.originalPath } }
      )
    } catch (err) {
      setError(getErrorMessage(err))
      setIsLoading(false)
    }
  }

  return (
    <div className="innerMain">
      <Heading h1>Login</Heading>
      <p className={styles.intro}>
        Enter your email and get ready to share your memories of the special
        day.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {error && <Alert message={error} />}
        <Field
          inputId="email"
          inputType="email"
          label="Email address"
          errorMessage={
            formErrors.emailAddress && 'Please enter a valid email address'
          }
          {...register('emailAddress', {
            required: true,
            pattern: {
              value: VALID_EMAIL_REGEX,
              message: 'Please enter a valid email address',
            },
          })}
        />
        <Button
          label="Submit"
          actionLabel="Submitting"
          disabled={isLoading || !isValid}
          isLoading={isLoading}
        />
      </form>
      <LegalLinks actionText="using" />
    </div>
  )
}

export default Login
