import { useEffectOnce } from '@hooks/useEffectOnce'
import 'vanilla-cookieconsent'
import 'vanilla-cookieconsent/dist/cookieconsent.css'

import pluginConfig from './CookieConsentConfig'

export const CookieConsent = () => {
  useEffectOnce(() => {
    if (!document.getElementById('cc--main')) {
      window.CC = window.initCookieConsent()
      window.CC.run(pluginConfig)
    }
  })

  return null
}
