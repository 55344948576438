import { createRoot } from 'react-dom/client'
import { initSentry } from '@lib/sentry'
import { ErrorBoundary } from '@sentry/react'
import { ErrorBoundaryContent } from '@modules/ErrorBoundaryContent'
import App from './App'

initSentry()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <ErrorBoundary fallback={<ErrorBoundaryContent />}>
    <App />
  </ErrorBoundary>
)
