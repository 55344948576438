import { AccordionItem } from '@components/AccordionItem'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { useParams } from 'react-router-dom'
import { ContactUs } from './ContactUs'
import { PhotoComments } from './PhotoComments'
import { UploadingPhotos } from './UploadingPhotos'
import { ViewingPhotos } from './ViewingPhotos'
import { YourGallery } from './YourGallery'
import { YourProfile } from './YourProfile'

import styles from './HelpCenter.module.css'

export const HelpCenter = () => {
  const { galleryId } = useParams()
  const { gallery } = useGalleryMetadata(galleryId as string)

  return (
    <div className={styles.helpCenter}>
      {gallery?.isHost && (
        <AccordionItem title="Your Gallery">
          <YourGallery />
        </AccordionItem>
      )}
      <AccordionItem title="Uploading Photos">
        <UploadingPhotos />
      </AccordionItem>
      <AccordionItem title="Viewing Photos">
        <ViewingPhotos />
      </AccordionItem>
      <AccordionItem title="Photo Comments">
        <PhotoComments />
      </AccordionItem>
      <AccordionItem title="Your Profile">
        <YourProfile />
      </AccordionItem>
      <AccordionItem title="Contact Us">
        <ContactUs />
      </AccordionItem>
    </div>
  )
}
