import useSWR from 'swr'
import { MediaMetadata } from '@lib/types'
import { API_URL } from '@lib/constants'

export function useMediaMetadata(mediaId: string | null) {
  const MEDIA_ENDPOINT = `${API_URL}/media/${mediaId}`
  const { data, mutate, error } = useSWR<MediaMetadata>(
    mediaId ? MEDIA_ENDPOINT : null,
    { shouldRetryOnError: false }
  )

  const loading = !data && !error

  return {
    error,
    loading,
    metadata: data,
    mutate,
  }
}
