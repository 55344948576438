import { Link } from 'react-router-dom'
import styles from './LegalLinks.module.css'

interface LegalLinksProps {
  actionText?: string
}

export const LegalLinks = ({
  actionText = 'creating a gallery on',
}: LegalLinksProps) => {
  return (
    <p className={styles.container}>
      By {actionText} Sharetheirday, you agree to our{' '}
      <Link to="/legal/terms-and-conditions">terms and conditions</Link> and{' '}
      <Link to="/legal/privacy-policy">privacy policy</Link>.
    </p>
  )
}
