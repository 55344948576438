import classNames from 'classnames'
import styles from './LoadingSpinner.module.css'

interface LoadingSpinnerProps {
  small?: boolean
  color?: string
}

export const LoadingSpinner = ({
  small = false,
  color = '#ffffff',
}: LoadingSpinnerProps) => {
  const cx = classNames(styles.loadingWrap, {
    [styles.small]: small,
  })
  return (
    <div className={cx}>
      <div className={styles['lds-ring']}>
        <div
          style={{
            borderColor: `${color} transparent transparent transparent`,
          }}
        ></div>
        <div
          style={{
            borderColor: `${color} transparent transparent transparent`,
          }}
        ></div>
        <div
          style={{
            borderColor: `${color} transparent transparent transparent`,
          }}
        ></div>
        <div
          style={{
            borderColor: `${color} transparent transparent transparent`,
          }}
        ></div>
      </div>
    </div>
  )
}
