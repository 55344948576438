import { Media as MediaType } from '@lib/types'
import { CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import { Link, useLocation } from 'react-router-dom'
import { PlayCircleIcon } from '@heroicons/react/24/solid'

import styles from './MediaGrid.module.css'

interface MediaGridProps {
  media?: MediaType[]
  pageSize: number
  galleryId: string
  slug: string
}

const DEFAULT_NAMED_TRANSFORMATION =
  't_media_lib_thumb_auto_format' as unknown as NamedTransformationAction

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

export const MediaGrid: React.FC<React.PropsWithChildren<MediaGridProps>> = ({
  media,
  galleryId,
  slug,
}) => {
  const location = useLocation()

  return (
    <div className={styles.mediaGrid}>
      <ul aria-hidden={!media?.length}>
        {media?.map((item) => {
          const linkTarget = `/gallery/${galleryId}/${slug}/${item.id}`

          const imgUrl =
            item.type === 'image'
              ? `${cldInstance
                  .image(`${galleryId}/${item.id}`)
                  .namedTransformation(DEFAULT_NAMED_TRANSFORMATION)
                  .toURL()}`
              : `${cldInstance
                  .video(`${galleryId}/${item.id}`)
                  .namedTransformation(DEFAULT_NAMED_TRANSFORMATION)
                  .toURL()}`

          return (
            <li key={`media-grid--${item.id}`}>
              <Link to={linkTarget} state={{ backgroundLocation: location }}>
                {item.type === 'video' && (
                  <span className={styles.playButton}>
                    <PlayCircleIcon width={24} height={24} color="#fff" />
                  </span>
                )}
                <img src={imgUrl} alt="" />
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
