import { ErrorMessage } from '@components/ErrorMessage'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { API_URL } from '@lib/constants'
import { timeAgo } from '@lib/date-utils'
import { useComments } from '@data/useComments'
import { AddComment } from './AddComment'
import { NameAndProfilePhoto } from '@components/NameAndProfilePhoto'

import styles from './Comments.module.css'

interface CommentsProps {
  mediaId: string
}

export const Comments: React.FC<React.PropsWithChildren<CommentsProps>> = ({
  mediaId,
}) => {
  const COMMENTS_ENDPOINT = `${API_URL}/media/${mediaId}/comments`
  const { comments, loading, error, mutate } = useComments(COMMENTS_ENDPOINT)

  return (
    <>
      {comments &&
        comments.map(
          ({ firstName, surname, text, createdAt, profilePhotoUrl }) => (
            <div className={styles.container} key={createdAt.toString()}>
              <NameAndProfilePhoto
                name={`${firstName} ${surname}`}
                photoId={profilePhotoUrl}
              />
              <div className={styles.commentText}>
                <p>{text}</p>
              </div>
              <div className={styles.meta}>
                <p>{timeAgo(Date.now() - Date.parse(createdAt.toString()))}</p>
              </div>
            </div>
          )
        )}
      {error && <ErrorMessage>Error loading comments</ErrorMessage>}
      {loading && <LoadingSpinner />}
      <AddComment fetchComments={mutate} mediaId={mediaId} />
    </>
  )
}
