import { ProgressItem } from './ProgressItem'
import styles from './Progress.module.css'
interface ProgressProps {
  currentPage: number
}

export const Progress = ({ currentPage }: ProgressProps) => {
  return (
    <div role="group" aria-label="progress" className={styles.container}>
      <ol>
        <ProgressItem
          isCurrent={currentPage === 1}
          label="Your Gallery"
          step={1}
        />
        <ProgressItem isCurrent={currentPage === 2} label="Payment" step={2} />
        <ProgressItem
          isCurrent={currentPage === 3}
          label="Confirmation"
          step={3}
        />
      </ol>
    </div>
  )
}
