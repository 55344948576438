import { SignalIcon } from '@heroicons/react/24/outline'
import styles from './SocialMessage.module.css'

export const SocialMessage = () => {
  return (
    <div className={styles.container}>
      <span className={styles.icon}>
        <SignalIcon width={28} height={28} className="colorize-independence" />
      </span>
      <p>
        Please don&apos;t share any photos of our day on social media until we
        remove this message, thank you!
      </p>
    </div>
  )
}
