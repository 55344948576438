import { Heading } from '@components/Heading'

export const YourGallery = () => {
  return (
    <>
      <section>
        <Heading h3>How do I change the welcome message?</Heading>
        <p>
          To change your welcome message on your gallery, click Gallery Settings
          in the main navigation on the left (or tap the More button in the
          footer menu on mobile).
        </p>
        <ul>
          <li>
            Click in the text box under Welcome Message, and write a welcoming
            message for your guests.
          </li>
          <li>Click save at the bottom of the screen when you&apos;re done.</li>
          <li>
            When you and your guests visit the main gallery page, you&apos;ll
            see the message in the head of the page above all the photos.
          </li>
        </ul>
        <p>
          You can change the message at any time, and include emojis if you
          like.
        </p>
      </section>
      <section>
        <Heading h3>How do I change the gallery photo?</Heading>
        <p>
          To change your gallery photo, click on Gallery Settings in the main
          navigation on the left (or tap the More button in the footer menu on
          mobile).
        </p>
        <ul>
          <li>
            If you&apos;ve not uploaded a photo yet click on the Upload button
            in the Gallery Photo section, or if you already have one but would
            like to change it click on the Change button.
          </li>
          <li>
            Select a new photo, then choose the crop you&apos;d like to use. We
            show a thumbnail example of what it&apos;ll look like to help you
            choose.
          </li>
          <li>
            When you&apos;re happy with the crop, click the save button at the
            bottom of the page.
          </li>
          <li>
            You and your guests will now see the photo on the header of your
            gallery, and in the gallery selection page.
          </li>
        </ul>
      </section>
      <section>
        <Heading h3>What is the Social media message?</Heading>
        <p>
          Not everyone wants their guests to share the photos of their special
          day on social media immediately, or at all.
        </p>
        <p>
          We&apos;ve included a small banner message that asks guests not to
          share photos on social media, until the banner is removed.
        </p>
        <p>
          You can hide and show the message at any time by clicking Gallery
          Settings in the main navigation on the left (or tapping the More
          button in the footer menu on mobile).
        </p>
        <ul>
          <li>
            In the Social Media Message section, you can check the box to hide
            the message, or uncheck it to show the message in the gallery.
          </li>
          <li>
            Once you&apos;re done, remember to click Save at the bottom of the
            page.
          </li>
        </ul>
      </section>
      <section>
        <Heading h3>How do I invite guests?</Heading>
        <p>
          The important part of any gallery is letting your guests know that
          it&apos;s here and ready for them to upload photos of your special
          day!
        </p>
        <p>
          You can invite as many guests as you like, by clicking on the Invite
          Guests link in the main navigation on the left (or tap the More button
          in the footer menu on mobile, then Invite Guests).
        </p>
        <ul>
          <li>
            On the following page, you can write your guests email addresses, or
            paste them in.
          </li>
          <li>
            Please separate the email addresses with commas, spaces, or line
            breaks.
          </li>
          <li>
            We&apos;ll show you an example message of what your guests will
            receive which includes the special code for them to access your
            gallery.
          </li>
          <li>
            Once you&apos;ve added your guests' emails, click on the send
            button, and we&apos;ll send the email invites immediately.
          </li>
        </ul>
        <p>
          Note: you can send invites multiple times, and we won&apos;t send
          emails to people you&apos;ve already invited (just in case you have
          duplicates in your list).
        </p>
      </section>
    </>
  )
}
