import { useNavigate } from 'react-router-dom'
import { useGalleries } from '@data/useGalleries'
import { Button } from '@components/Button'
import { ErrorMessage } from '@components/ErrorMessage'
import { API_URL } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { trackEvent } from '@lib/tracking/trackEvent'
import { GalleryNameAndImage } from '@components/GalleryNameAndImage'
import { ChevronRightIcon } from '@heroicons/react/24/outline'

import styles from './GalleryList.module.css'

const ENDPOINT = `${API_URL}/galleries`

export const GalleryList = () => {
  const navigate = useNavigate()

  const { updateUser, user } = useUserContext()

  const { galleries, loading, error } = useGalleries(ENDPOINT)

  const filteredGalleries = galleries?.length
    ? galleries.filter((gal) => {
        if (gal.type === 'guest') {
          return gal.activeFrom
        }
        return true
      })
    : []

  const handleCompleteGallery = (id: string) => {
    updateUser({ currentGallery: id })
    navigate('/gallery/payment')
  }

  const handleViewGallery = (id: string, slug: string) => {
    updateUser({ currentGallery: id })
    navigate(`/gallery/${id}/${slug}`)
    trackEvent('View Gallery clicked', {
      gallery_id: id,
      automatic_navigation: false,
    })
  }

  return (
    <div className={styles.container}>
      {error && <ErrorMessage>Could not load galleries</ErrorMessage>}
      {filteredGalleries && filteredGalleries.length > 0 && (
        <ul>
          {filteredGalleries.map((gallery) => (
            <li
              key={gallery.id}
              onClick={(ev) => {
                ev.preventDefault()
                handleViewGallery(gallery.id, gallery.slug)
              }}
            >
              <GalleryNameAndImage
                name={gallery.name}
                eventDate={new Date(gallery.eventDate)}
                size="regular"
                imageUrl={gallery.photoUrl || ''}
              />
              <div className={styles.actions}>
                {!gallery.activeFrom ? (
                  <Button
                    onClick={(ev) => {
                      ev.preventDefault()
                      handleCompleteGallery(gallery.id)
                    }}
                    label="Complete Setup"
                  />
                ) : (
                  <button
                    className={styles.viewButton}
                    onClick={(ev) => {
                      ev.preventDefault()
                      handleViewGallery(gallery.id, gallery.slug)
                    }}
                  >
                    <ChevronRightIcon
                      title="View"
                      className="colorize-desert-sand"
                      width={24}
                      height={24}
                    />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      )}
      {!loading && galleries && galleries.length === 0 && (
        <p className="none-found">No galleries found</p>
      )}
    </div>
  )
}
