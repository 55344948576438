import useSWR from 'swr'
import { API_URL } from '@lib/constants'
import { ApiErrorBody, Gallery } from '@lib/types'
import { prepareFetcherError } from '@modules/SWRConfigProvider'

export function useGalleryMetadata(
  galleryId: string | null,
  errorRetryCount = 0
) {
  const GALLERY_ENDPOINT = `${API_URL}/galleries/${galleryId}`

  const galleryFetcher = async (resource: string, init: RequestInit) => {
    const res = await fetch(resource, { ...init, credentials: 'include' })

    if (!res.ok) {
      const body: ApiErrorBody = await res.json()
      const error = prepareFetcherError(body)
      throw error
    }

    const { eventDate, ...rest } = await res.json()
    return { ...rest, eventDate: new Date(eventDate) }
  } 

  const { data, mutate, error } = useSWR<Gallery>(
    galleryId ? GALLERY_ENDPOINT : null,
    galleryFetcher,
    {
      errorRetryCount,
    }
  )

  const loading = !data && !error

  return {
    error,
    loading,
    gallery: data,
    mutate,
  }
}
