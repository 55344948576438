import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import { CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import { trackEvent } from '@lib/tracking'
import { Dispatch, SetStateAction } from 'react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

const ORIGINAL_IMAGE_NAMED_TRANSFORMATION =
  't_media_lib_original' as unknown as NamedTransformationAction

const ORIGINAL_VIDEO_NAMED_TRANSFORMATION =
  `t_media_lib_detail_video_download` as unknown as NamedTransformationAction

interface DownloadOriginalButtonProps {
  galleryId: string
  mediaId: string
  type: 'image' | 'video'
  setShowMenu: Dispatch<SetStateAction<boolean>>
}

export const DownloadOriginalButton = ({
  galleryId,
  mediaId,
  type,
  setShowMenu,
}: DownloadOriginalButtonProps) => {
  const handleDownloadClick = (ev: React.SyntheticEvent) => {
    ev.preventDefault()
    setShowMenu(false)
    if (galleryId && mediaId) {
      trackEvent('Download Full Size clicked', {
        gallery_id: galleryId,
        media_id: mediaId,
      })
    }
    // handle download here
    if (type === 'image') {
      const imgUrl = `${cldInstance
        .image(`${galleryId}/${mediaId}`)
        .namedTransformation(ORIGINAL_IMAGE_NAMED_TRANSFORMATION)
        .toURL()}`

      window.open(imgUrl)
    } else {
      const videoURL = `${cldInstance
        .video(`${galleryId}/${mediaId}`)
        .namedTransformation(ORIGINAL_VIDEO_NAMED_TRANSFORMATION)
        .toURL()}`
      window.open(videoURL)
    }
  }

  return (
    <button type="button" onClick={handleDownloadClick}>
      <ArrowDownTrayIcon width={20} height={20} title="Download full size" />
    </button>
  )
}
