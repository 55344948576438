import { CLOUDINARY_CLOUD_NAME } from './constants'

interface CloudinaryEvent {
  event: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info?: any
}

interface CloudinaryWidgetParams {
  folder: string
  context: string
  callback: (mediaId: string) => void
  mutateCallback: () => void
}

export const cloudinaryUploadWidget = ({
  folder,
  context,
  callback,
  mutateCallback,
}: CloudinaryWidgetParams) => {
  if (window.cloudinary) {
    return window.cloudinary.createUploadWidget(
      {
        cloudName: CLOUDINARY_CLOUD_NAME,
        uploadPreset: 'preset1',
        sources: ['local', 'camera'],
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        prepareUploadParams: (cb: any, params: any) => {
          cb({
            ...params,
            folder,
            context, // `gid=${gid} | uid=${uid}`,
          })
        },
      },
      (error: string | null, result: CloudinaryEvent) => {
        if (!error && result.event === 'success') {
          callback(result.info)
        }
        if (!error && result.event === 'close') {
          mutateCallback()
        }
      }
    )
  } else {
    console.error('Cloudinary Script not loaded!')
  }
}
