import { Button } from '@components/Button'

import styles from './LoadMore.module.css'

interface Props {
  handleClick: (ev: React.SyntheticEvent<HTMLButtonElement>) => void
}

export const LoadMore = ({ handleClick }: Props) => (
  <div className={styles.loadMore}>
    <Button type="submit" label="Load more" onClick={handleClick} />
  </div>
)
