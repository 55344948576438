import { FC } from 'react'

import AppContext from './AppContext'
import { initialiseMixpanel } from '@lib/tracking'
import { CookieConsent } from '@components/CookieConsent'

import '../styles/reset.css'
import '../styles/globals.css'

initialiseMixpanel()

const App: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <AppContext />
      <CookieConsent />
    </>
  )
}

export default App
