import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { ErrorMessage } from '@components/ErrorMessage'
import { Heading } from '@components/Heading'
import { Activity as ActivityModule } from '@modules/gallery/activity/Activity'
import { useHandleGalleryError } from '@hooks/useHandleGalleryError'

const Activity = () => {
  const { galleryId } = useParams()
  const { gallery, loading, error } = useGalleryMetadata(galleryId as string)

  useHandleGalleryError(galleryId)

  useEffect(() => {
    if (gallery) {
      document.title = `Activity - ${gallery?.name} Gallery - Sharetheirday`
    }
  }, [gallery])

  return (
    <>
      {error && <ErrorMessage>Error loading gallery data</ErrorMessage>}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="innerMain">
          <Heading h1>Activity</Heading>
          <ActivityModule />
        </div>
      )}
    </>
  )
}

export default Activity
