import { useState } from 'react'
import { Heading } from '@components/Heading'
import styles from './AccordionItem.module.css'

export interface AccordionItemProps {
  title: string
}

export const AccordionItem: React.FC<
  React.PropsWithChildren<AccordionItemProps>
> = ({ title, children }) => {
  const [isActive, setIsActive] = useState(false)
  const arrowStyles = { transform: 'rotate(90deg)' }

  return (
    <div className={styles.accordionItem}>
      <div
        className={styles.accordionTitle}
        onClick={() => setIsActive(!isActive)}
      >
        <Heading h2>{title}</Heading>
        <img
          src="/img/icons/arrow-right.svg"
          alt=""
          width="14"
          height="14"
          className="colorize-independence"
          style={isActive ? { ...arrowStyles } : {}}
        />
      </div>
      {isActive && <div className={styles.accordionContent}>{children}</div>}
    </div>
  )
}
