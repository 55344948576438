import { useUserContext } from '@lib/contexts/user-context'
import {
  INTERCOM_API_BASE,
  INTERCOM_APP_ID,
  INTERCOM_CUSTOM_LAUNCHER_ID,
} from '..'

export const loadIntercomMessenger = () => {
  const { user } = useUserContext()

  if (user) {
    const { firstName, surname, email, id } = user
    const fullName = `${firstName} ${surname}`

    window.Intercom('boot', {
      api_base: INTERCOM_API_BASE,
      app_id: INTERCOM_APP_ID,
      custom_launcher_selector: `#${INTERCOM_CUSTOM_LAUNCHER_ID}`,
      name: fullName,
      user_id: id,
      email: email,
    })
  }
}
