import { ExclamationCircleIcon } from '@heroicons/react/24/solid'

import styles from './ErrorMessage.module.css'
interface ErrorMessageProps {
  children: React.ReactNode
}

export const ErrorMessage = ({ children }: ErrorMessageProps) => {
  return (
    <div className={styles.errorMessage}>
      <ExclamationCircleIcon width={24} height={24} color="#ef4444" />
      <p>{children}</p>
    </div>
  )
}
