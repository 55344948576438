import { APP_ENVIRONMENT, MIXPANEL_TOKEN } from '@lib/constants'
import { getAnalyticsObject } from '@lib/tracking'

export const initialiseMixpanel = () => {
  const mixpanel = getAnalyticsObject()

  mixpanel &&
    mixpanel.init(MIXPANEL_TOKEN, {
      debug: ['dev', 'local'].includes(APP_ENVIRONMENT),
      ignore_dnt: true,
      api_host: 'https://stats.sharetheirday.com',
      persistence: 'localStorage',
      disable_persistence: true,
    })
}
