import { Alert } from '@components/Alert'
import { Button } from '@components/Button'
import { Select } from '@components/Select'
import { TextArea } from '@components/TextArea'
import { API_URL } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { getErrorMessage } from '@lib/get-error-message'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import styles from './ReportMediaForm.module.css'

interface ReportMediaFormProps {
  onSuccess: () => void
  mediaId: string
}

interface Inputs {
  reason: string
  moreInfo: string
}

const REASONS = [
  { label: 'Copyright issues', value: 'copyright' },
  { label: 'Nudity', value: 'nudity' },
  { label: 'Violence', value: 'violence' },
  { label: 'Encouraging harm', value: 'encouraging-harm' },
  { label: 'Other', value: 'other' },
]

export const ReportMediaForm = ({
  onSuccess,
  mediaId,
}: ReportMediaFormProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const ADD_REPORT_ENDPOINT = `${API_URL}/media/${mediaId}/report`

  const { register, handleSubmit, watch } = useForm<Inputs>({
    defaultValues: {
      reason: '',
    },
  })

  const onSubmit = async ({ reason, moreInfo }: Inputs) => {
    try {
      await fetchApiData(ADD_REPORT_ENDPOINT, {
        method: 'POST',
        data: {
          reason,
          moreInfo,
        },
      })
      setIsSubmitting(true)
      setSubmitted(true)
    } catch (err) {
      setError(getErrorMessage(err))
    }
  }

  const reason = watch('reason')

  return (
    <div>
      {submitted ? (
        <div className={styles.success}>
          <p>Thank you for letting us know your concerns.</p>
          <p>
            One of our team will take a look as soon as possible, and we&apos;ll
            be in touch via email.
          </p>
          <Button label="Return to the gallery" onClick={onSuccess} />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <p>Please let us know if you feel this is inappropriate.</p>
          {error && <Alert message={error} />}
          <Select
            inputId="reason"
            options={REASONS}
            label="Reason"
            labelPosition="top"
            includePleaseSelect
            {...register('reason', { required: true })}
          />
          <TextArea
            label="Tell us more (optional)"
            inputId="moreInfo"
            rows={3}
            {...register('moreInfo')}
          />
          <Button
            label="Send report"
            actionLabel="Sending..."
            isLoading={isSubmitting}
            disabled={!reason.length || isSubmitting}
          />
          <p className={styles.cancel}>
            or <a onClick={onSuccess}>Cancel</a>
          </p>
        </form>
      )}
    </div>
  )
}
