import * as React from 'react'
import classNames from 'classnames'
import styles from './Checkbox.module.css'

export interface CheckboxProps {
  name: string
  label: string
  inputId: string
  className?: string
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ name, label, inputId, className = '', ...rest }, ref) => (
    <div className={classNames(styles.checkbox, className)}>
      <label htmlFor={inputId}>
        <input name={name} type="checkbox" id={inputId} ref={ref} {...rest} />
        <span>{label}</span>
      </label>
    </div>
  )
)
