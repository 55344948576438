import { Heading } from '@components/Heading'
import styles from './PaymentSummary.module.css'

export const PaymentSummary = () => {
  return (
    <div className={styles.container}>
      <div>
        <Heading h2>Your wedding gallery</Heading>
        <ul>
          <li>Unlimited photos & videos</li>
          <li>Unlimited guests</li>
          <li>
            6 months storage <span>(from wedding date)</span>
          </li>
        </ul>
      </div>
      <div className={styles.pricing}>
        <p>£99</p>
      </div>
    </div>
  )
}

export default PaymentSummary
