import useMediaQuery from '@hooks/useMediaQuery'
import { FooterNav } from './FooterNav'
import styles from './GalleryFooter.module.css'

export const GalleryFooter = () => {
  return (
    <>
      {useMediaQuery('(max-width: 720px)') && (
        <footer className={styles.footer}>
          <div className={styles.inner}>
            <FooterNav />
          </div>
        </footer>
      )}
    </>
  )
}
