import { useParams } from 'react-router-dom'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { useGalleryActivity } from '@data/useGalleryActivity'
import { NotificationsCount } from '@components/NotificationsCount'
import {
  PhotoIcon,
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  FolderOpenIcon,
  Cog8ToothIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline'

import { NavItem } from './NavItem'
import styles from './GalleryNav.module.css'

export const GalleryNav = () => {
  const { galleryId, slug } = useParams()
  const { gallery } = useGalleryMetadata(galleryId as string)
  const { notifications } = useGalleryActivity(galleryId as string)

  const galleryLink = `/gallery/${galleryId}/${slug}`

  return (
    <nav className={styles.nav}>
      <ul>
        <NavItem
          url={galleryLink}
          Icon={PhotoIcon}
          iconWidth={16}
          iconHeight={16}
          label="Gallery"
        />
        <NavItem
          url={`${galleryLink}/activity`}
          Icon={ChatBubbleLeftIcon}
          iconWidth={16}
          iconHeight={16}
          label="Activity"
          notificationsComponent={
            notifications.length > 0 ? (
              <NotificationsCount count={notifications.length} isSideBar />
            ) : null
          }
        />
        {gallery?.isHost && (
          <>
            <NavItem
              url={`${galleryLink}/invite-guests`}
              Icon={EnvelopeIcon}
              iconWidth={16}
              iconHeight={16}
              label="Invite Guests"
            />
            <NavItem
              url={`${galleryLink}/albums`}
              Icon={FolderOpenIcon}
              iconWidth={16}
              iconHeight={16}
              label="Albums"
            />
            <NavItem
              url={`${galleryLink}/settings`}
              Icon={Cog8ToothIcon}
              iconWidth={16}
              iconHeight={16}
              label="Gallery Settings"
            />
          </>
        )}
        <NavItem
          url={`${galleryLink}/help`}
          Icon={QuestionMarkCircleIcon}
          iconWidth={16}
          iconHeight={16}
          label="Help Center"
        />
      </ul>
    </nav>
  )
}
