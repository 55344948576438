import { Heading } from '@components/Heading'

import styles from './ErrorBoundaryContent.module.css'

export const ErrorBoundaryContent = () => {
  return (
    <div className={styles.container}>
      <img src="/img/error.svg" alt="" />
      <Heading h1>Whoops, something went wrong!</Heading>
      <p>
        Our team has been notified, and will be working to fix the problem as
        soon as possible. <br />
        Please try again, or contact our team through the help section.
      </p>
    </div>
  )
}
