enum MembershipType {
  host = 'host',
  guest = 'guest',
}

export enum NotificationRegularity {
  none = 'none',
  daily = 'daily',
  weekly = 'weekly',
}

export interface UserNotificationPreferences {
  newMediaEmail: NotificationRegularity
  newCommentEmail: NotificationRegularity
}

export interface User {
  id: string | null
  email: string | null
  firstName: string | null
  surname: string | null
  currentGallery: string | null
  isAuthenticated: boolean
  isCreatingGallery: boolean
  preferences: UserNotificationPreferences
  photoUrl: string | null
}
export interface GalleryEntity {
  id: string
  slug: string
  name: string
  eventDate: Date
  activeFrom: string | null
  socialShareAllowed: boolean
  isHost: boolean
  message: string | null
  photoUrl: string | null
  accessCode?: string
}

export interface GalleryWithMembership extends GalleryEntity {
  type: MembershipType
}

export type Gallery = GalleryWithMembership | GalleryEntity

export interface MediaComment {
  id: number
  text: string
  createdAt: Date
  firstName: string
  surname: string
  profilePhotoUrl?: string
}

export interface Pageable {
  total: number
  hasNext: boolean
  pageCount: number
}

export interface GalleryMedia {
  data: Media[]
  _meta: Pageable
}

export interface MediaMetadata {
  id: string
  type: 'video' | 'image'
  galleryId: string
  uploadedBy: string
  uploadedByProfilePhoto: string
  width: number
  height: number
  favourite: boolean
  commentCount: number
  albums: Album[]
}

export interface ApiErrorBody {
  statusCode: number
  message: string
  error: string
}

export interface FetchError extends Error {
  info?: ApiErrorBody
  status?: number
}

export interface ActivityOriginator {
  id: string
  firstName: string
  surname: string
}

export enum ActivityType {
  media = 'media',
  comment = 'comment',
}

export interface ActivityNotification {
  id: string
  timestamp: string
  type: ActivityType
  status: string
  originator: ActivityOriginator
  mediaId: string
}

export interface GalleryActivity {
  data: ActivityNotification[]
}

export interface AlbumsList {
  data: Album[]
}

export interface Album {
  id: string
  name: string
}

export interface MediaAlbum {
  id: string
  name: string
}

export interface Media {
  id: string
  type: 'image' | 'video'
  width: number
  height: number
  galleryId: string
  uploadedBy: string
  uploadedByProfilePhoto: string | null
  commentCount: number
  albums: MediaAlbum[]
  favourite: boolean
}
