import { Heading } from '@components/Heading'

export const TermsAndConditions = () => {
  return (
    <>
      <Heading h1>Terms and conditions</Heading>
      <p>
        These terms and conditions (&#8220;Agreement&#8221;) set forth the
        general terms and conditions of your use of the{' '}
        <a href="https://www.sharetheirday.com">sharetheirday.com</a> website
        (&#8220;Website&#8221; or &#8220;Service&#8221;) and any of its related
        products and services (collectively, &#8220;Services&#8221;). This
        Agreement is legally binding between you (&#8220;User&#8221;,
        &#8220;you&#8221; or &#8220;your&#8221;) and Sharetheirday Ltd
        (&#8220;Sharetheirday Ltd&#8221;, &#8220;we&#8221;, &#8220;us&#8221; or
        &#8220;our&#8221;). If you are entering into this agreement on behalf of
        a business or other legal entity, you represent that you have the
        authority to bind such entity to this agreement, in which case the terms
        &#8220;User&#8221;, &#8220;you&#8221; or &#8220;your&#8221; shall refer
        to such entity. If you do not have such authority, or if you do not
        agree with the terms of this agreement, you must not accept this
        agreement and may not access and use the Website and Services. By
        accessing and using the Website and Services, you acknowledge that you
        have read, understood, and agree to be bound by the terms of this
        Agreement. You acknowledge that this Agreement is a contract between you
        and Sharetheirday Ltd, even though it is electronic and is not
        physically signed by you, and it governs your use of the Website and
        Services.
      </p>
      <div>
        <Heading h2>Table of contents</Heading>
        <ol className="toc">
          <li>
            <a href="#accounts-and-membership">Accounts and membership</a>
          </li>
          <li>
            <a href="#user-content">User content</a>
          </li>
          <li>
            <a href="#backups">Backups</a>
          </li>
          <li>
            <a href="#links-to-other-resources">Links to other resources</a>
          </li>
          <li>
            <a href="#prohibited-uses">Prohibited uses</a>
          </li>
          <li>
            <a href="#intellectual-property-rights">
              Intellectual property rights
            </a>
          </li>
          <li>
            <a href="#limitation-of-liability">Limitation of liability</a>
          </li>
          <li>
            <a href="#indemnification">Indemnification</a>
          </li>
          <li>
            <a href="#severability">Severability</a>
          </li>
          <li>
            <a href="#changes-and-amendments">Changes and amendments</a>
          </li>
          <li>
            <a href="#acceptance-of-these-terms">Acceptance of these terms</a>
          </li>
          <li>
            <a href="#contacting-us">Contacting us</a>
          </li>
        </ol>
      </div>
      <Heading h2 id="accounts-and-membership">
        Accounts and membership
      </Heading>
      <p>
        You must be at least 16 years of age to use the Website and Services. By
        using the Website and Services and by agreeing to this Agreement you
        warrant and represent that you are at least 16 years of age.
      </p>
      <p>
        If you create an account on the Website, you are responsible for
        maintaining the security of your account and you are fully responsible
        for all activities that occur under the account and any other actions
        taken in connection with it. We may, but have no obligation to, monitor
        and review new accounts before you may sign in and start using the
        Services. Providing false contact information of any kind may result in
        the termination of your account. You must immediately notify us of any
        unauthorized uses of your account or any other breaches of security. We
        will not be liable for any acts or omissions by you, including any
        damages of any kind incurred as a result of such acts or omissions.
      </p>
      <p>
        We may suspend, disable, or delete your account (or any part thereof) if
        we determine that you have violated any provision of this Agreement or
        that your conduct or content would tend to damage our reputation and
        goodwill. If we delete your account for the foregoing reasons, you may
        not re-register for our Services. We may block your email address and
        Internet protocol address to prevent further registration.
      </p>
      <Heading h2 id="user-content">
        User content
      </Heading>
      <p>
        We do not own any data, information or material (collectively,
        &#8220;Content&#8221;) that you submit on the Website in the course of
        using the Service. You shall have sole responsibility for the accuracy,
        quality, integrity, legality, reliability, appropriateness, and
        intellectual property ownership or right to use of all submitted
        Content. We may, but have no obligation to, monitor and review the
        Content on the Website submitted or created using our Services by you.
        You grant us permission to access, copy, distribute, store, transmit,
        reformat, display and perform the Content of your user account solely as
        required for the purpose of providing the Services to you.{' '}
      </p>
      <p>
        Without limiting any of those representations or warranties, we have the
        right, though not the obligation, to, in our own sole discretion, refuse
        or remove any Content that, in our reasonable opinion, violates any of
        our policies or is in any way harmful or objectionable. Unless
        specifically permitted by you, your use of the Website and Services does
        not grant us the license to use, reproduce, adapt, modify, publish or
        distribute the Content created by you or stored in your user account for
        commercial, marketing or any similar purpose.
      </p>
      <Heading h2 id="backups">
        Backups
      </Heading>
      <p>
        We perform regular backups of the Website and its Content and will do
        our best to ensure completeness and accuracy of these backups. In the
        event of the hardware failure or data loss we will restore backups
        automatically to minimize the impact and downtime.
      </p>
      <Heading h2 id="links-to-other-resources">
        Links to other resources
      </Heading>
      <p>
        Although the Website and Services may link to other resources (such as
        websites, mobile applications, etc.), we are not, directly or
        indirectly, implying any approval, association, sponsorship,
        endorsement, or affiliation with any linked resource, unless
        specifically stated herein. We are not responsible for examining or
        evaluating, and we do not warrant the offerings of, any businesses or
        individuals or the content of their resources. We do not assume any
        responsibility or liability for the actions, products, services, and
        content of any other third parties. You should carefully review the
        legal statements and other conditions of use of any resource which you
        access through a link on the Website. Your linking to any other off-site
        resources is at your own risk.
      </p>
      <Heading h2 id="prohibited-uses">
        Prohibited uses
      </Heading>
      <p>
        In addition to other terms as set forth in the Agreement, you are
        prohibited from using the Website and Services or Content:
      </p>
      <ol>
        <li>(a) for any unlawful purpose;</li>
        <li>
          (b) to solicit others to perform or participate in any unlawful acts;
        </li>
        <li>
          (c) to violate any international, federal, provincial or state
          regulations, rules, laws, or local ordinances;
        </li>
        <li>
          (d) to infringe upon or violate our intellectual property rights or
          the intellectual property rights of others;
        </li>
        <li>
          (e) to harass, abuse, insult, harm, defame, slander, disparage,
          intimidate, or discriminate based on gender, sexual orientation,
          religion, ethnicity, race, age, national origin, or disability;
        </li>
        <li>(f) to submit false or misleading information;</li>
        <li>
          (g) to upload or transmit viruses or any other type of malicious code
          that will or may be used in any way that will affect the functionality
          or operation of the Website and Services, third party products and
          services, or the Internet;
        </li>
        <li>(h) to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
        <li>(i) for any obscene or immoral purpose;</li>
        <li>
          (j) to interfere with or circumvent the security features of the
          Website and Services, third party products and services, or the
          Internet. We reserve the right to terminate your use of the Website
          and Services for violating any of the prohibited uses.
        </li>
      </ol>
      <Heading h2 id="intellectual-property-rights">
        Intellectual property rights
      </Heading>
      <p>
        &#8220;Intellectual Property Rights&#8221; means all present and future
        rights conferred by statute, common law or equity in or in relation to
        any copyright and related rights, trademarks, designs, patents,
        inventions, goodwill and the right to sue for passing off, rights to
        inventions, rights to use, and all other intellectual property rights,
        in each case whether registered or unregistered and including all
        applications and rights to apply for and be granted, rights to claim
        priority from, such rights and all similar or equivalent rights or forms
        of protection and any other results of intellectual activity which
        subsist or will subsist now or in the future in any part of the world.
      </p>
      <p>
        This Agreement does not transfer to you any intellectual property owned
        by Sharetheirday Ltd or third parties, and all rights, titles, and
        interests in and to such property will remain (as between the parties)
        solely with Sharetheirday Ltd. All trademarks, service marks, graphics
        and logos used in connection with the Website and Services, are
        trademarks or registered trademarks of Sharetheirday Ltd or its
        licensors. Other trademarks, service marks, graphics and logos used in
        connection with the Website and Services may be the trademarks of other
        third parties. Your use of the Website and Services grants you no right
        or license to reproduce or otherwise use any of Sharetheirday Ltd or
        third party trademarks.
      </p>
      <Heading h2 id="limitation-of-liability">
        Limitation of liability
      </Heading>
      <p>
        To the fullest extent permitted by applicable law, in no event will
        Sharetheirday Ltd, its affiliates, directors, officers, employees,
        agents, suppliers or licensors be liable to any person for any indirect,
        incidental, special, punitive, cover or consequential damages
        (including, without limitation, damages for lost profits, revenue,
        sales, goodwill, use of content, impact on business, business
        interruption, loss of anticipated savings, loss of business opportunity)
        however caused, under any theory of liability, including, without
        limitation, contract, tort, warranty, breach of statutory duty,
        negligence or otherwise, even if the liable party has been advised as to
        the possibility of such damages or could have foreseen such damages.
      </p>
      <p>
        To the maximum extent permitted by applicable law, the aggregate
        liability of Sharetheirday Ltd and its affiliates, officers, employees,
        agents, suppliers and licensors relating to the services will be limited
        to an amount no greater than one pound or any amounts actually paid in
        cash by you to Sharetheirday Ltd for the prior one month period prior to
        the first event or occurrence giving rise to such liability. The
        limitations and exclusions also apply if this remedy does not fully
        compensate you for any losses or fails of its essential purpose.
      </p>
      <Heading h2 id="indemnification">
        Indemnification
      </Heading>
      <p>
        You agree to indemnify and hold Sharetheirday Ltd and its affiliates,
        directors, officers, employees, agents, suppliers and licensors harmless
        from and against any liabilities, losses, damages or costs, including
        reasonable attorneys&#8217; fees, incurred in connection with or arising
        from any third party allegations, claims, actions, disputes, or demands
        asserted against any of them as a result of or relating to your Content,
        your use of the Website and Services or any willful misconduct on your
        part.
      </p>
      <Heading h2 id="severability">
        Severability
      </Heading>
      <p>
        All rights and restrictions contained in this Agreement may be exercised
        and shall be applicable and binding only to the extent that they do not
        violate any applicable laws and are intended to be limited to the extent
        necessary so that they will not render this Agreement illegal, invalid
        or unenforceable.
      </p>
      <p>
        If any provision or portion of any provision of this Agreement shall be
        held to be illegal, invalid or unenforceable by a court of competent
        jurisdiction, it is the intention of the parties that the remaining
        provisions or portions thereof shall constitute their agreement with
        respect to the subject matter hereof, and all such remaining provisions
        or portions thereof shall remain in full force and effect.
      </p>
      <Heading h2 id="changes-and-amendments">
        Changes and amendments
      </Heading>
      <p>
        We reserve the right to modify this Agreement or its terms related to
        the Website and Services at any time at our discretion. When we do, we
        will revise the updated date at the bottom of this page. We may also
        provide notice to you in other ways at our discretion, such as through
        the contact information you have provided.
      </p>
      <p>
        An updated version of this Agreement will be effective immediately upon
        the posting of the revised Agreement unless otherwise specified. Your
        continued use of the Website and Services after the effective date of
        the revised Agreement (or such other act specified at that time) will
        constitute your consent to those changes.
      </p>
      <Heading h2 id="acceptance-of-these-terms">
        Acceptance of these terms
      </Heading>
      <p>
        You acknowledge that you have read this Agreement and agree to all its
        terms and conditions. By accessing and using the Website and Services
        you agree to be bound by this Agreement. If you do not agree to abide by
        the terms of this Agreement, you are not authorized to access or use the
        Website and Services.
      </p>
      <Heading h2 id="contacting-us">
        Contacting us
      </Heading>
      <p>
        If you have any questions, concerns, or complaints regarding this
        Agreement, we encourage you to contact us using the details below:
      </p>
      <p>
        <a href="&#109;&#097;&#105;&#108;&#116;&#111;&#058;&#104;e&#108;p&#64;sh&#97;r&#101;&#116;&#104;&#101;&#105;&#114;da&#121;&#46;&#99;om">
          &#104;el&#112;&#64;&#115;&#104;a&#114;&#101;&#116;&#104;&#101;i&#114;d&#97;y&#46;co&#109;
        </a>
      </p>
      <p>This document was last updated on March 13, 2023</p>
    </>
  )
}
