import { Heading } from '@components/Heading'
import { trackEvent } from '@lib/tracking'
import { useState } from 'react'
import { ReportMediaForm } from './ReportMediaForm'
import styles from './MediaMetadata.module.css'
import { useParams } from 'react-router-dom'
import { NameAndProfilePhoto } from '@components/NameAndProfilePhoto'
import { useUserContext } from '@lib/contexts/user-context'
import { DownloadOriginalButton } from './DownloadOriginalButton'
import { DeleteMediaButton } from './DeleteMediaButton'
import { Albums } from './Albums'
import { FavouriteButton } from './FavouriteButton'
import { useGalleryAlbums } from '@data/useGalleryAlbums'
import { Media } from '@lib/types'
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline'

interface MediaMetadataProps {
  inMediaCard?: boolean
  metadata: Media
}

export const MediaMetadata = ({
  inMediaCard = false,
  metadata,
}: MediaMetadataProps) => {
  const { galleryId, slug } = useParams()
  const { albums: availableAlbums } = useGalleryAlbums(galleryId as string)
  const [showMenu, setShowMenu] = useState(false)
  const [reportingMedia, setReportingMedia] = useState(false)

  const { user } = useUserContext()
  const userName = user?.firstName + ' ' + user?.surname
  const isImageByUser = userName === metadata.uploadedBy

  const handleMenuClick = () => {
    setShowMenu((prevState) => !prevState)
  }

  const handleReportImageClick = (ev: React.SyntheticEvent) => {
    ev.preventDefault()
    if (galleryId && metadata.id) {
      trackEvent('Report Media clicked', {
        gallery_id: galleryId,
        media_id: metadata.id,
      })
    }
    setReportingMedia(true)
    setShowMenu(false)
  }

  return (
    <div className={styles.container}>
      <header>
        <NameAndProfilePhoto
          name={metadata.uploadedBy}
          photoId={metadata.uploadedByProfilePhoto}
        />
        <div className={styles.moreOptions}>
          {!inMediaCard && availableAlbums.length > 0 && (
            <Albums metadata={metadata} />
          )}
          <FavouriteButton
            isFavourited={metadata.favourite}
            mediaId={metadata.id}
          />
          {galleryId && (
            <DownloadOriginalButton
              galleryId={galleryId}
              mediaId={metadata.id}
              type={metadata.type}
              setShowMenu={setShowMenu}
            />
          )}
          <button type="button" onClick={handleMenuClick}>
            <EllipsisHorizontalIcon width={20} height={20} />
          </button>
          {showMenu && (
            <ul className={styles.moreList}>
              <li>
                <button type="button" onClick={handleReportImageClick}>
                  Report this {metadata.type}
                </button>
              </li>
              {isImageByUser && galleryId && slug && (
                <li>
                  <DeleteMediaButton
                    setShowMenu={setShowMenu}
                    galleryId={galleryId}
                    mediaId={metadata.id}
                    slug={slug}
                  />
                </li>
              )}
            </ul>
          )}
        </div>
      </header>
      {reportingMedia && (
        <div className={styles.report}>
          <Heading h3>Report media</Heading>
          <ReportMediaForm
            mediaId={metadata.id}
            onSuccess={() => {
              setReportingMedia(false)
            }}
          />
        </div>
      )}
    </div>
  )
}
