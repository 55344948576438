import { API_URL } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { useEffect, useState } from 'react'
import { useGalleryParamsContext } from '@lib/contexts/gallery-params-context'
import { useParams } from 'react-router-dom'
import { useMediaList } from '@data/useMediaList'
import { useSWRConfig } from 'swr'
import { unstable_serialize } from 'swr/infinite'
import { HeartIcon } from '@heroicons/react/24/outline'
import { HeartIcon as HeartIconSolid } from '@heroicons/react/24/solid'

interface FavouriteButtonProps {
  mediaId: string
  isFavourited: boolean
}

const MEDIA_ENDPOINT = `${API_URL}/media`

export const FavouriteButton = ({
  mediaId,
  isFavourited,
}: FavouriteButtonProps) => {
  const { mutate } = useSWRConfig()
  const { galleryId } = useParams()
  const { sort, filter, orderBy, album } = useGalleryParamsContext()
  const { media, meta } = useMediaList(
    galleryId as string,
    sort,
    filter,
    orderBy,
    album
  )
  const [isSaving, setIsSaving] = useState(false)
  const [favourited, setFavourited] = useState(isFavourited)

  useEffect(() => {
    if (isFavourited !== favourited) {
      setFavourited(isFavourited)
    }
  }, [isFavourited])

  const handleButtonClick = async () => {
    setIsSaving(true)
    const ENDPOINT = `${MEDIA_ENDPOINT}/${mediaId}/favourites`
    try {
      let method: 'POST' | 'DELETE' = 'POST'
      if (favourited) {
        method = 'DELETE'
      }

      const result = await fetchApiData(ENDPOINT, {
        method,
      })

      mutate(
        (key) => typeof key === 'string' && key.includes('/media?'),
        {
          _meta: meta,
          data: media.map((m) => (m.id === mediaId ? result : m)),
        },
        { revalidate: false }
      )

      mutate(
        (key) =>
          typeof key === 'string' && new RegExp(`/media/${mediaId}$`).test(key),
        result,
        { revalidate: false }
      )

      setFavourited(result.favourite)
      setIsSaving(false)
    } catch (err) {}
  }

  return (
    <button type="button" onClick={handleButtonClick} disabled={isSaving}>
      {favourited ? (
        <HeartIconSolid
          width={20}
          height={20}
          className="colorize-independence"
        />
      ) : (
        <HeartIcon width={20} height={20} className="colorize-independence" />
      )}
    </button>
  )
}
