import { Select } from '@components/Select'
import useMediaQuery from '@hooks/useMediaQuery'
import {
  FilterOption,
  OrderByOption,
  SortOption,
  AlbumOption,
  useGalleryParamsContext,
} from '@lib/contexts/gallery-params-context'
import React from 'react'
import classNames from 'classnames'
import styles from './ActionBar.module.css'
import { useGalleryAlbums } from '@data/useGalleryAlbums'
import { useParams } from 'react-router-dom'

const arrowStyles = { transform: 'rotate(180deg)' }

export const ActionBar: React.FC = () => {
  const { galleryId } = useParams()
  const {
    sort,
    setSort,
    filter,
    setFilter,
    orderBy,
    setOrderBy,
    album,
    setAlbum,
  } = useGalleryParamsContext()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width: 975px)')
  const { albums } = useGalleryAlbums(galleryId as string)

  const handleFilterChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    ev.preventDefault()
    if (ev.target.value !== filter) {
      setFilter(ev.target.value as FilterOption)
    }
  }

  const handleSortChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    ev.preventDefault()
    if (ev.target.value !== sort) {
      setSort(ev.target.value as SortOption)
    }
  }

  const handleOrderByChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    ev.preventDefault()
    if (ev.target.value !== sort) {
      setOrderBy(ev.target.value as OrderByOption)
    }
  }

  const handleAlbumChange = (ev: React.ChangeEvent<HTMLSelectElement>) => {
    ev.preventDefault()
    if (ev.target.value !== album) {
      setAlbum(ev.target.value as AlbumOption)
      window.scrollTo(0, 0)
    }
  }

  const filterSelects = (
    <div>
      <Select
        className={styles.select}
        inputId="filter"
        label="Filter:"
        labelPosition="left"
        onChange={handleFilterChange}
        value={filter}
        options={[
          { label: 'Show all', value: 'all' },
          { label: 'Show mine', value: 'mine' },
        ]}
      />
      <Select
        className={styles.select}
        inputId="orderBy"
        label="Order:"
        labelPosition="left"
        onChange={handleOrderByChange}
        value={orderBy}
        options={[
          { label: 'Date & time taken', value: 'takenAt' },
          { label: 'Most recent added', value: 'createdAt' },
        ]}
      />
      <Select
        className={styles.select}
        inputId="sort"
        label="Sort:"
        labelPosition="left"
        onChange={handleSortChange}
        value={sort}
        options={[
          { label: 'Most recent first', value: 'desc' },
          { label: 'Oldest first', value: 'asc' },
        ]}
      />
    </div>
  )

  const albumsSelectClassNames = classNames(
    styles.select,
    styles.selectNoMargin
  )

  return (
    <>
      <div className={styles.actionBar}>
        <span className={styles.total}>
          <Select
            className={albumsSelectClassNames}
            inputId="albums"
            label="Album:"
            labelPosition="left"
            onChange={handleAlbumChange}
            value={album}
            options={[
              { label: 'All photos', value: 'all' },
              { label: 'Favourites', value: 'favourites' },
              ...albums.map((a) => ({ label: a.name, value: a.id })),
            ]}
          />
        </span>

        {isMobile && (
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={styles.filtersButton}
          >
            <span>Filters & Sorting</span>
            <img
              src="/img/icons/arrow-down.svg"
              alt=""
              width="8"
              height="8"
              className="colorize-desert-sand"
              style={isOpen ? { ...arrowStyles } : {}}
            />
          </div>
        )}

        {!isMobile && filterSelects}
      </div>

      {isMobile && isOpen && (
        <div className={styles.filtersPanel}>{filterSelects}</div>
      )}
    </>
  )
}
