import { Heading } from '@components/Heading'
import styles from './ListAlbums.module.css'
import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryAlbums } from '@data/useGalleryAlbums'
import { Button } from '@components/Button'
import { DeleteAlbumButton } from './DeleteAlbumButton'
import { EditableAlbumName } from './EditableAlbumName'

export const ListAlbums = () => {
  const { galleryId } = useParams()
  const { albums, loading, mutate } = useGalleryAlbums(galleryId as string)

  const handleComplete = () => {
    mutate()
  }

  return (
    <div className={styles.container}>
      <Heading h2>Your Albums</Heading>
      {loading && <LoadingSpinner color="#999" />}
      {!loading && albums && albums.length > 0 && (
        <ul className={styles.list}>
          {albums.map((album) => (
            <li key={album.id}>
              <EditableAlbumName
                name={album.name}
                id={album.id}
                galleryId={galleryId as string}
                onEdited={handleComplete}
              />
              <DeleteAlbumButton
                galleryId={galleryId as string}
                albumId={album.id}
                onDeleted={handleComplete}
              />
            </li>
          ))}
        </ul>
      )}
      {!loading && albums.length === 0 && (
        <p className={styles.noAlbums}>
          You've not added any albums yet, why not add one now?
        </p>
      )}
    </div>
  )
}
