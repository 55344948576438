import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import { Button } from '@components/Button'
import { ButtonAsLink } from '@components/ButtonAsLink'
import { Heading } from '@components/Heading'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { API_URL, CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { Gallery } from '@lib/types'
import React, { Dispatch, SetStateAction, useRef, useState } from 'react'
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  Crop,
  makeAspectCrop,
  PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { useParams } from 'react-router-dom'
import { KeyedMutator } from 'swr'
import { canvasPreview } from './canvasPreview'
import styles from './EditProfilePhoto.module.css'

const UPLOAD_URL = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/upload`
const DEFAULT_NAMED_TRANSFORMATION =
  't_gallery_profile' as unknown as NamedTransformationAction

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

interface ProfilePhotoProps {
  savedPhoto: string | null
  setSavedPhoto: Dispatch<SetStateAction<string | null>>
  folder: string
  deleteEndpoint: string
  title: string
  description: string
  mutate?: KeyedMutator<Gallery>
  isGalleryPhoto?: boolean
}

export const EditProfilePhoto = ({
  savedPhoto,
  setSavedPhoto,
  folder,
  deleteEndpoint,
  title,
  description,
  mutate,
  isGalleryPhoto,
}: ProfilePhotoProps) => {
  const { galleryId } = useParams()
  const { user, updateUser } = useUserContext()
  const [isUploading, setIsUploading] = useState(false)
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState<Crop>({
    unit: '%',
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  })
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null)
  const imgRef = useRef<HTMLImageElement>(null)

  const photoUrl = savedPhoto
    ? `${cldInstance
        .image(`${folder}/${savedPhoto}`)
        .namedTransformation(DEFAULT_NAMED_TRANSFORMATION)
        .toURL()}`
    : null

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || '')
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { width, height } = e.currentTarget

    const crop = centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        1,
        width,
        height
      ),
      width,
      height
    )

    setCrop(crop)
    setCompletedCrop(convertToPixelCrop(crop, width, height))
  }

  const handleRemovePhoto = async (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    try {
      await fetchApiData(deleteEndpoint, {
        method: 'DELETE',
      })
      mutate && mutate()
      setSavedPhoto(null)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSavePhoto = async (
    e: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    e.preventDefault()
    setIsUploading(true)
    if (user && completedCrop && imgRef.current) {
      try {
        const dataURL = canvasPreview(imgRef.current, completedCrop)

        const res = await fetchApiData(UPLOAD_URL, {
          method: 'POST',
          includeCredentials: false,
          data: {
            upload_preset: 'preset1',
            folder,
            file: dataURL,
          },
        })

        const photoId = res.public_id.split('/')[1]

        setSavedPhoto(photoId)

        const ENDPOINT_URL = isGalleryPhoto
          ? `${API_URL}/galleries/${galleryId}`
          : `${API_URL}/profiles/${user?.id}/profile-photo`

        const payload = {
          photoUrl: photoId,
        }

        await fetchApiData(ENDPOINT_URL, {
          data: payload,
          method: 'PUT',
        })

        if (!isGalleryPhoto) {
          updateUser({
            photoUrl: photoId,
          })
        }

        setImgSrc('')
        setCompletedCrop(null)
        setIsUploading(false)
      } catch (err) {
        console.error(err)
        setIsUploading(false)
      }
    }
  }

  return (
    <>
      <Heading h2>{title}</Heading>
      <div className={styles.photo}>
        {photoUrl ? (
          <img src={photoUrl} alt="" width="100" height="100" />
        ) : (
          <div className={styles.profileImage}>
            <span>
              <img
                src="/img/stdy-icon.svg"
                alt=""
                className="colorize-alice-blue"
                width="50"
                height="50"
              />
            </span>
          </div>
        )}
        <div>
          <p>{description}</p>
          <p>
            {savedPhoto ? (
              <ButtonAsLink onClick={handleRemovePhoto}>
                Remove photo
              </ButtonAsLink>
            ) : (
              <>
                <label htmlFor="file-upload" className={styles.uploadButton}>
                  Choose a photo
                </label>
                <input
                  id="file-upload"
                  accept="image/*"
                  type="file"
                  className={styles.fileField}
                  onChange={handleSelectFile}
                />
              </>
            )}
          </p>
        </div>
      </div>
      {!!imgSrc && !isUploading && (
        <div className={styles.cropper}>
          <Heading h3>Crop your photo</Heading>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={1}
          >
            <img ref={imgRef} alt="Crop me" src={imgSrc} onLoad={onImageLoad} />
          </ReactCrop>
          <br />
          <br />
          <Button
            label="Upload cropped photo"
            onClick={handleSavePhoto}
            disabled={!completedCrop}
          />
        </div>
      )}
      {isUploading && (
        <div className={styles.uploading}>
          <LoadingSpinner color="#999" />
          <p>Uploading and saving photo</p>
        </div>
      )}
    </>
  )
}
