import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from '@lib/constants'
import { StripeForm } from './StripeForm'

interface StripeElementsProps {
  clientSecret: string
}

const stripePromise = loadStripe(STRIPE_KEY)

export const StripeElements = ({ clientSecret }: StripeElementsProps) => {
  return (
    <Elements stripe={stripePromise}>
      <StripeForm clientSecret={clientSecret} />
    </Elements>
  )
}
