import { useParams } from 'react-router-dom'
import { MetaData } from '@modules/gallery'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useMediaMetadata } from '@data/useMediaMetadata'
import { ErrorMessage } from '@components/ErrorMessage'
import { useGalleryMetadata } from '@data/useGalleryMetadata'

import { useEffect } from 'react'
import { MediaCard } from '@components/MediaCard'
interface GalleryDetailPageProps {
  isModal?: boolean
}

const GalleryDetailPage = ({ isModal }: GalleryDetailPageProps) => {
  const { mediaId, galleryId } = useParams()

  const { gallery } = useGalleryMetadata(galleryId as string)
  const { metadata, loading, error } = useMediaMetadata(mediaId as string)

  useEffect(() => {
    if (gallery) {
      document.title = `${gallery?.name} Gallery - Sharetheirday`
    }
  }, [gallery])

  return (
    <>
      {!isModal && <MetaData />}
      {loading && <LoadingSpinner />}
      {error && (
        <ErrorMessage>Error loading media: {error.message}</ErrorMessage>
      )}
      {metadata && mediaId && !error && <MediaCard data={metadata} />}
    </>
  )
}

export default GalleryDetailPage
