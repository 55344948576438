import fetchBuilder from 'fetch-retry-ts'

const API_RETRY_DELAY = 5000
const API_RETRY_ON_ERROR_CODES = [404]

/* eslint-disable @typescript-eslint/no-explicit-any */
interface FetchApiDataOptions {
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE'
  extraHeaders?: Record<string, any>
  data?: Record<string, any>
  retries?: number
  signal?: AbortController['signal']
  includeCredentials?: boolean
}

export const fetchApiData = async (
  endpoint: string,
  {
    method = 'GET',
    extraHeaders = {},
    data = {},
    retries = 0,
    signal,
    includeCredentials = true,
  }: FetchApiDataOptions = {}
): Promise<any> => {
  const body =
    (method === 'POST' || method === 'PUT' || method === 'DELETE') &&
    Object.keys(data).length
      ? JSON.stringify(data)
      : null

  const fetchWithRetries = fetchBuilder(fetch, {
    retries,
    retryDelay: API_RETRY_DELAY,
    retryOn: API_RETRY_ON_ERROR_CODES,
  })

  const options: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...extraHeaders,
    },
    body,
    signal,
  }

  if (includeCredentials) {
    options.credentials = 'include'
  }

  const res = await fetchWithRetries(endpoint, options)

  if (!res.ok) {
    if (res.status === 400) {
      const errRes = await res.json()
      throw new Error(errRes.message)
    }
    throw new Error('HTTP status ' + res.status)
  }

  const result = res.status !== 204 ? await res.json() : undefined
  return result
}
