import { LoadingSpinner } from '@components/LoadingSpinner'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { API_URL } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { useState } from 'react'
import { StripeElements } from './StripeElements'

export const PanelCard = () => {
  const [clientSecret, setClientSecret] = useState<string>()
  const { user } = useUserContext()

  useEffectOnce(() => {
    getClientSecret()
  })

  const getClientSecret = async () => {
    try {
      const endpoint = `${API_URL}/payments/intent`
      const { cs } = await fetchApiData(endpoint, {
        method: 'POST',
        data: {
          galleryId: user?.currentGallery,
        },
      })
      setClientSecret(cs)
    } catch (err) {
      console.log(err)
    }
  }

  if (!clientSecret) {
    return (
      <div>
        <LoadingSpinner />
      </div>
    )
  }

  return <StripeElements clientSecret={clientSecret} />
}
