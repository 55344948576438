import * as React from 'react'

export type SortOption = 'asc' | 'desc'
export type FilterOption = 'all' | 'mine'
export type OrderByOption = 'createdAt' | 'takenAt'
export type AlbumOption = 'all' | 'favourites'

interface GalleryParamsContextType {
  sort: SortOption
  filter: FilterOption
  orderBy: OrderByOption
  album: AlbumOption
  setSort: React.Dispatch<React.SetStateAction<SortOption>>
  setFilter: React.Dispatch<React.SetStateAction<FilterOption>>
  setOrderBy: React.Dispatch<React.SetStateAction<OrderByOption>>
  setAlbum: React.Dispatch<React.SetStateAction<AlbumOption>>
}

export const GalleryParamsContext =
  React.createContext<GalleryParamsContextType>({
    sort: 'desc',
    filter: 'all',
    orderBy: 'createdAt',
    album: 'all',
    setSort: () => {},
    setFilter: () => {},
    setOrderBy: () => {},
    setAlbum: () => {},
  })

export const GalleryParamsContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [sort, setSort] = React.useState<SortOption>('desc')
  const [filter, setFilter] = React.useState<FilterOption>('all')
  const [orderBy, setOrderBy] = React.useState<OrderByOption>('createdAt')
  const [album, setAlbum] = React.useState<AlbumOption>('all')

  const moreMenuContextValue: GalleryParamsContextType = {
    sort,
    filter,
    orderBy,
    album,
    setSort,
    setFilter,
    setOrderBy,
    setAlbum,
  }

  return (
    <GalleryParamsContext.Provider value={moreMenuContextValue}>
      {children}
    </GalleryParamsContext.Provider>
  )
}

export const useGalleryParamsContext = () =>
  React.useContext<GalleryParamsContextType>(GalleryParamsContext)
