import { getAnalyticsObject } from './getAnalyticsObject'
import { TrackingEventMap } from './trackingEvents'

export type TrackingEvent = keyof TrackingEventMap

export const trackEvent = async <T_Event extends TrackingEvent>(
  event: T_Event,
  properties: TrackingEventMap[T_Event]
) => {
  const analytics = getAnalyticsObject()

  if (!analytics) {
    return
  }

  analytics.track(event, properties)
}
