import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { ErrorMessage } from '@components/ErrorMessage'
import { Heading } from '@components/Heading'
import { InviteGuests as InviteGuestsForm } from '@modules/gallery'
import { useEffect, useState } from 'react'
import { useHandleGalleryError } from '@hooks/useHandleGalleryError'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import styles from './InviteGuests.module.css'
import { API_URL, APP_URL } from '@lib/constants'

const DEFAULT_COPY_LABEL = 'Copy to clipboard'

const InviteGuests = () => {
  const { galleryId } = useParams()
  const { gallery, loading, error } = useGalleryMetadata(galleryId as string)
  const [clipboardLabel, setClipboardLabel] = useState(DEFAULT_COPY_LABEL)

  useHandleGalleryError(galleryId)

  useEffect(() => {
    if (gallery) {
      document.title = `Invite Guests - ${gallery?.name} Gallery - Sharetheirday`
    }
  }, [gallery])

  if (!gallery) return null

  const galleryJoinLink = `${APP_URL}/gallery/join?ac=${gallery.accessCode}`

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(galleryJoinLink)
    setClipboardLabel('Copied!')
    setTimeout(() => {
      setClipboardLabel(DEFAULT_COPY_LABEL)
    }, 3000)
  }

  const handleDownloadClick = async (
    ev: React.SyntheticEvent<HTMLButtonElement>
  ) => {
    ev.preventDefault()
    try {
      const ENDPOINT = `${API_URL}/galleries/${galleryId}/info-cards`

      const result = await fetch(ENDPOINT, {
        method: 'GET',
        credentials: 'include',
        headers: { 'Content-Type': 'application/json' },
      })

      const blob = await result.blob()

      const href = window.URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = href
      link.setAttribute('download', 'gallery-info-cards.pdf')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      {error && <ErrorMessage>Error loading gallery data</ErrorMessage>}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="innerMain">
          <Heading h1>Invite Guests</Heading>
          <p>
            Your gallery comes to life once your guests get involved! Use any or
            all of the methods below to encourage people to join and upload
            their memories.
          </p>
          <ul className={styles.steps}>
            <li>
              <Heading h2>Send a link</Heading>
              <p>
                Copy and paste the below link into an email or in your wedding
                invites:
              </p>
              <p className={styles.joinLink}>
                <span>{galleryJoinLink}</span>
                <button onClick={copyLinkToClipboard}>
                  <DocumentDuplicateIcon
                    width={20}
                    height={20}
                    className="colorize-desert-sand"
                  />{' '}
                  {clipboardLabel}
                </button>
              </p>
            </li>
            <li>
              <Heading h2>Download &amp; print</Heading>
              <img
                src="/img/invitesheet.png"
                alt=""
                className={styles.inviteSheet}
              />
              <p>
                If you want an easy way to invite your guests by sending them
                something in the mail, then you can download and print off some
                custom made invites below:
              </p>
              <button
                className={styles.downloadPdf}
                onClick={handleDownloadClick}
              >
                <img src="/img/pdf.svg" alt="" height="48" /> Download PDF
              </button>
            </li>
            <li>
              <Heading h2>Send a message</Heading>
              <p>
                Add your guests emails below and we'll send them an invite to
                your gallery.
              </p>
              <p className={styles.introsub}>
                Email addresses can be entered one per line or be separated by
                commas or spaces.
              </p>
              <div className={styles.message}>
                <div className={styles.preview}>
                  <Heading h3>Message preview</Heading>
                  <p>Dear Guest,</p>
                  <p>
                    You&apos;ve been invited to join the gallery of{' '}
                    {gallery.name} to upload photos and videos of their special
                    day.
                  </p>
                  <p>You can access their gallery using the below link:</p>
                  <p>{galleryJoinLink}</p>
                  <p>Thank you for being a part of Sharetheirday!</p>
                  <p>Much love,</p>
                  <p>The Sharetheirday Team</p>
                </div>
                <InviteGuestsForm />
              </div>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default InviteGuests
