import { init } from '@sentry/react'
import { SENTRY_DSN, APP_ENVIRONMENT } from '@lib/constants'

export const initSentry = () => {
  if (APP_ENVIRONMENT === 'local') {
    return
  }

  init({
    dsn: SENTRY_DSN,
    environment: APP_ENVIRONMENT,
  })
}
