import { API_URL, PAYPAL_KEY } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useNavigate } from 'react-router-dom'

import styles from './PanelPaypal.module.css'

export const PanelPaypal = () => {
  const navigate = useNavigate()
  const { user } = useUserContext()

  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        <LoadingSpinner small color="#0070ba" />
      </div>
      <PayPalScriptProvider
        options={{
          'client-id': PAYPAL_KEY,
          currency: 'GBP',
          components: 'buttons',
          'data-namespace': 'paypal-container',
        }}
      >
        <PayPalButtons
          createOrder={async () => {
            try {
              const ep = `${API_URL}/payments/create`
              const { id } = await fetchApiData(ep, {
                method: 'POST',
                data: {
                  gid: user?.currentGallery,
                },
              })
              return id
            } catch (err) {
              console.log(err)
            }
          }}
          onApprove={async (data, actions) => {
            try {
              const ep = `${API_URL}/payments/capture`
              const orderData = await fetchApiData(ep, {
                method: 'POST',
                data: {
                  orderId: data.orderID,
                },
              })

              const errorDetail =
                Array.isArray(orderData.details) && orderData.details[0]

              if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                return actions.restart() // Recoverable state, per:
                // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
              }

              if (errorDetail) {
                // set error state here
              } else {
                navigate('/gallery/confirmation')
              }
            } catch (err) {
              console.log(err)
            }
          }}
          style={{
            color: 'blue',
            shape: 'rect',
            label: 'pay',
            layout: 'horizontal',
            tagline: false,
          }}
        />
      </PayPalScriptProvider>
    </div>
  )
}
