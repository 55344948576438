import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ErrorMessage } from '@components/ErrorMessage'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryActivity } from '@data/useGalleryActivity'
import { API_URL } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { ActivityItem } from './ActivityItem'
import styles from './Activity.module.css'
import { NoActivity } from './NoActivity'

export const Activity = () => {
  const { galleryId } = useParams()
  const { notifications, error, loading } = useGalleryActivity(
    galleryId as string
  )
  const { mutate } = useGalleryActivity(galleryId as string)
  const [isClearing, setIsClearing] = useState(false)

  const handleClearAllNotifications = async () => {
    try {
      setIsClearing(true)
      const CLEAR_NOTIFICATION_ENDPOINT = `${API_URL}/activities/notifications`
      const ids = notifications.map((n) => n.id)
      await fetchApiData(CLEAR_NOTIFICATION_ENDPOINT, {
        method: 'PUT',
        data: {
          ids,
        },
      })
      mutate()
      setIsClearing(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={styles.container}>
      {loading && <LoadingSpinner />}
      {!loading && error && (
        <ErrorMessage>There was a problem loading latest activity</ErrorMessage>
      )}
      {!loading && !error && !notifications.length && <NoActivity />}
      {!loading && !error && notifications.length > 0 && (
        <>
          <span className={styles.actions}>
            <button onClick={handleClearAllNotifications} disabled={isClearing}>
              {isClearing ? 'Clearing' : 'Clear all'}
            </button>
          </span>
          <ul>
            {notifications.map((notification) => (
              <ActivityItem key={notification.id} {...notification} />
            ))}
          </ul>
        </>
      )}
    </div>
  )
}
