import { useEffect, useRef } from 'react'
import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import VLiteJs from 'vlitejs'
import 'vlitejs/vlite.css'
import './vlitejs.override.css'

export interface VideoProps {
  cldInstance: Cloudinary
  video: string
}

/**
 * Returns Cloudinary image URL with srcset and sizes
 */
export const Video = ({ cldInstance, video, ...rest }: VideoProps) => {
  const videoRef = useRef(null)

  const posterSrc = `${cldInstance
    .video(`${video}.jpg`)
    .namedTransformation(
      `t_media_lib_detail_auto_format` as unknown as NamedTransformationAction
    )
    .toURL()}`

  useEffect(() => {
    const videoPlayer = new VLiteJs(videoRef.current, {
      options: {
        controls: true,
        autoplay: false,
        playPause: true,
        progressBar: true,
        time: true,
        volume: true,
        fullscreen: true,
        bigPlay: true,
        loop: false,
        muted: false,
        autoHide: true,
        poster: posterSrc,
      },
    })

    return () => {
      videoPlayer.destroy()
    }
  }, [])

  const src = `${cldInstance
    .video(video)
    .namedTransformation(
      `t_media_lib_detail_video_auto_format` as unknown as NamedTransformationAction
    )
    .toURL()}`

  return <video ref={videoRef} src={src} {...rest}></video>
}
