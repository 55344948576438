import { ButtonAsLink } from '@components/ButtonAsLink'
import { API_URL } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { useState } from 'react'

export const ResendLoginCode: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { user } = useUserContext()
  const [resentCode, setResentCode] = useState(false)

  const fetchLoginCode = async () => {
    const email = user?.email

    const payload = {
      email,
    }

    // send it
    try {
      const response = await fetchApiData(`${API_URL}/auth/login`, {
        method: 'POST',
        data: payload,
      })

      if (response) {
        setResentCode(true)
        setTimeout(() => {
          setResentCode(false)
        }, 5000)
      }
    } catch (err) {
      console.log('err', err)
      // handle errors
    }
  }

  return resentCode ? (
    <span>Sent!</span>
  ) : (
    <>
      Not received it after 2 minutes?{' '}
      <ButtonAsLink onClick={fetchLoginCode} alternateStyle>
        Resend
      </ButtonAsLink>
    </>
  )
}
