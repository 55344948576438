import classNames from 'classnames'
import { Link } from 'react-router-dom'

import styles from './LinkButton.module.css'

interface LinkButtonProps {
  href: string
  label: string
  as?: string
  disabled?: boolean
}

export const LinkButton: React.FC<React.PropsWithChildren<LinkButtonProps>> = ({
  href,
  label,
  disabled = false,
}) => {
  const cx = classNames(styles.linkButton, {
    [styles.linkButtonDisabled]: disabled,
  })
  return (
    <Link to={href} className={cx}>
      {label}
    </Link>
  )
}
