import React, { useState } from 'react'
import { KeyedMutator } from 'swr'
import { useParams } from 'react-router-dom'
import { TextArea } from '@components/TextArea'
import { Button } from '@components/Button'
import { API_URL } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { getErrorMessage } from '@lib/get-error-message'
import { MediaComment } from '@lib/types'
import styles from './AddComment.module.css'
interface AddCommentProps {
  fetchComments: KeyedMutator<MediaComment[]>
  mediaId: string
}

export const AddComment = ({ fetchComments, mediaId }: AddCommentProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const ADD_COMMENT_ENDPOINT = `${API_URL}/comments`

  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(event.target.value)
  }

  // Handle enter key
  const handleSubmit = async (event: React.FormEvent) => {
    try {
      event.preventDefault()
      setIsSaving(true)

      await fetchApiData(ADD_COMMENT_ENDPOINT, {
        method: 'POST',
        data: {
          text: comment,
          mediaId,
        },
      })

      setComment('')
      fetchComments()
    } catch (err) {
      console.log(getErrorMessage(err))
    } finally {
      setIsSaving(false)
    }
  }

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <TextArea
        inputId="new-comment"
        name="new-comment"
        placeholder="Add your comment"
        rows={3}
        onChange={onChange}
        value={comment}
      />
      <Button label="Add comment" disabled={!comment.length || isSaving} />
    </form>
  )
}
