import * as React from 'react'

interface MoreMenuContextType {
  showMoreMenu: boolean
  moreMenuShouldShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const MoreMenuContext = React.createContext<MoreMenuContextType>({
  showMoreMenu: false,
  moreMenuShouldShow: () => {},
})

export const MoreMenuContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [menuShowing, setMenuShowing] = React.useState<boolean>(false)

  const moreMenuContextValue: MoreMenuContextType = {
    showMoreMenu: menuShowing,
    moreMenuShouldShow: setMenuShowing,
  }

  return (
    <MoreMenuContext.Provider value={moreMenuContextValue}>
      {children}
    </MoreMenuContext.Provider>
  )
}

export const useMoreMenuContext = () =>
  React.useContext<MoreMenuContextType>(MoreMenuContext)
