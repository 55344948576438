import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { fetchApiData } from '@lib/fetch-api-data'
import { API_URL, PATHS } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { identifyUser } from '@lib/tracking'

const AUTH_CHECK_ENDPOINT = `${API_URL}/auth/check`

export const RequireAuth = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { user, updateUser } = useUserContext()
  const [isChecking, setIsChecking] = useState(false)
  const [nextRoute, setNextRoute] = useState('')

  const checkAuthStatus = async () => {
    setIsChecking(true)
    try {
      const {
        id,
        firstName,
        surname,
        currentGallery,
        email,
        preferences,
        photoUrl,
      } = await fetchApiData(AUTH_CHECK_ENDPOINT)
      updateUser({
        id,
        email,
        firstName,
        surname,
        currentGallery,
        isAuthenticated: true,
        preferences,
        photoUrl,
      })
      if (!firstName || !surname) {
        setNextRoute(PATHS.PROFILE)
      }
    } catch (err) {
      setNextRoute(PATHS.LOGIN)
    } finally {
      setIsChecking(false)
    }
  }

  useEffectOnce(() => {
    if (!user?.isAuthenticated) {
      checkAuthStatus()
    } else if (
      (!user.firstName || !user.surname) &&
      window.location.pathname !== PATHS.PROFILE
    ) {
      setNextRoute(PATHS.PROFILE)
    }
  })

  useEffectOnce(() => {
    if (user?.id && user?.email) {
      identifyUser(user?.id, user?.email)
    }
  })

  useEffect(() => {
    if (nextRoute && nextRoute !== window.location.pathname) {
      navigate(nextRoute, { state: { originalPath: location.pathname } })
    }
  }, [nextRoute])

  return !isChecking && !!user?.isAuthenticated ? (
    <Outlet />
  ) : (
    <LoadingSpinner color="#ababab" />
  )
}
