import { Alert } from '@components/Alert'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const localStorageIsAvailable = () => {
  const test = 'test'
  try {
    window.localStorage.setItem(test, test)
    window.localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

const VoucherLanding = () => {
  const navigate = useNavigate()
  const { code } = useParams()
  const [noLocalStorage, setNoLocalStorage] = useState(false)

  useEffectOnce(() => {
    // add code to localstorage and continue to gallery new page, passing flash
    if (localStorageIsAvailable()) {
      if (code && code.length === 10) {
        window.localStorage.setItem('svc', code)
        navigate('/gallery/new', { state: { voucher: true } })
      } else {
        navigate('gallery/new')
      }
    } else {
      setNoLocalStorage(true)
    }
  })

  return noLocalStorage ? (
    <>
      <Alert message="You have local storage turned off in your browser - please enable it to redeem your gift voucher, and try again." />
    </>
  ) : (
    <>
      <p>Processing</p>
      <br />
      <LoadingSpinner color="#999999" />
    </>
  )
}

export default VoucherLanding
