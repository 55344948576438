import { UserContextProvider } from '@lib/contexts/user-context'
import { SWRConfigProvider } from '@modules/SWRConfigProvider'
import Routing from './Routing'
import { MoreMenuContextProvider } from '@lib/contexts/more-menu-context'
import { BrowserRouter } from 'react-router-dom'
import { GalleryParamsContextProvider } from '@lib/contexts/gallery-params-context'
import { ScrollToTop } from '@modules/ScrollToTop'

function AppContext() {
  return (
    <>
      <SWRConfigProvider>
        <UserContextProvider>
          <MoreMenuContextProvider>
            <GalleryParamsContextProvider>
              <div id="app">
                <BrowserRouter>
                  <ScrollToTop />
                  <Routing />
                </BrowserRouter>
              </div>
            </GalleryParamsContextProvider>
          </MoreMenuContextProvider>
        </UserContextProvider>
      </SWRConfigProvider>
    </>
  )
}

export default AppContext
