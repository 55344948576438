import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { useUserContext } from '@lib/contexts/user-context'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const useHandleGalleryError = (galleryId: string | undefined) => {
  if (!galleryId) return

  const navigate = useNavigate()
  const { error } = useGalleryMetadata(galleryId)
  const { updateUser } = useUserContext()

  useEffect(() => {
    if (error) {
      if (error.status === 401) {
        navigate('/gallery/join')
      }
      if (error.status === 404) {
        navigate('/')
      }
      if (error.status === 402) {
        updateUser({ currentGallery: galleryId })
        navigate('/gallery/payment')
      }
    }
  }, [error])
}
