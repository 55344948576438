import { INTERCOM_CUSTOM_LAUNCHER_ID } from '@lib/constants'

export const ContactUs = () => (
  <section>
    <p>
      <a id={INTERCOM_CUSTOM_LAUNCHER_ID} href="mailto:help@sharetheirday.com">
        Send us a message now
      </a>
    </p>
  </section>
)
