import { useMediaList } from '@data/useMediaList'
import { API_URL } from '@lib/constants'
import { useGalleryParamsContext } from '@lib/contexts/gallery-params-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { trackEvent } from '@lib/tracking'
import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'

interface DeleteMediaButtonProps {
  setShowMenu: Dispatch<SetStateAction<boolean>>
  mediaId: string
  galleryId: string
  slug: string
}

const onDelete = async (mediaId: string) => {
  const DELETE_MEDIA_ENDPOINT = `${API_URL}/media/${mediaId}`
  try {
    return await fetchApiData(DELETE_MEDIA_ENDPOINT, {
      method: 'DELETE',
    })
  } catch (err) {
    console.error('Photo was not deleted')
  }
}

export const DeleteMediaButton = ({
  setShowMenu,
  mediaId,
  galleryId,
  slug,
}: DeleteMediaButtonProps) => {
  const navigate = useNavigate()

  const { sort, filter, orderBy, album } = useGalleryParamsContext()
  const { mutate } = useMediaList(
    galleryId as string,
    sort,
    filter,
    orderBy,
    album
  )

  const handleDeleteClick = async (ev: React.SyntheticEvent) => {
    ev.preventDefault()

    setShowMenu(false)

    if (window.confirm('Are you sure you want to delete it?')) {
      if (galleryId && mediaId) {
        trackEvent('Delete image clicked', {
          gallery_id: galleryId,
          media_id: mediaId,
        })
      }

      const result = await onDelete(mediaId)
      if (result) {
        mutate()
        navigate(`/gallery/${galleryId}/${slug}`)
      }
    }
  }

  return (
    <button type="button" onClick={handleDeleteClick}>
      Delete
    </button>
  )
}
