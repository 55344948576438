import { useEffect } from 'react'
import { useLocation, Routes, Route } from 'react-router-dom'
import { Layout, GalleryLayout } from '@components/Layouts'
import { HostOnly } from '@modules/HostOnly'
import { RequireAuth } from '@modules/RequireAuth'
import { GalleryModal } from '@modules/gallery/listing/GalleryModal'
import { FallbackProvider } from '@modules/FallbackProvider'
import { LegalLayout } from '@components/Layouts/LegalLayout'

import { FourOhFour } from './pages/404'
import GalleryPage from './pages/gallery/GalleryPage'
import GalleryDetailPage from './pages/gallery/GalleryDetailPage'
import InviteGuests from './pages/gallery/InviteGuests'
import Settings from './pages/gallery/Settings'
import Help from './pages/gallery/Help'
import Activity from './pages/gallery/Activity'
import { TermsAndConditions } from './pages/legal/TermsAndConditions'
import { PrivacyPolicy } from './pages/legal/PrivacyPolicy'

import Root from './pages/Root'
import Confirm from './pages/auth/Confirm'
import Login from './pages/auth/Login'
import NewProfile from './pages/profile/New'
import NewGallery from './pages/gallery/creation/New'
import Payment from './pages/gallery/creation/Payment'
import Confirmation from './pages/gallery/creation/Confirmation'

import JoinGallery from './pages/gallery/Join'
import EditProfilePage from './pages/profile/Edit'
import VoucherLanding from './pages/gallery/creation/VoucherLanding'
import { ManageAlbumsPage } from './pages/gallery/ManageAlbums'

const Routing = () => {
  const location = useLocation()

  const state = location.state as { backgroundLocation?: Location }

  useEffect(() => {
    if (state?.backgroundLocation) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [state])

  return (
    <>
      <FallbackProvider>
        <Routes location={state?.backgroundLocation || location}>
          <Route path="legal" element={<LegalLayout />}>
            <Route
              path="terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="/" element={<Layout />}>
            <Route path="*" element={<FourOhFour />} />
            <Route element={<RequireAuth />}>
              <Route index element={<Root />} />
            </Route>
            <Route path="*" element={<FourOhFour />} />
            <Route path="auth">
              <Route path="login" element={<Login />} />
              <Route path="confirm" element={<Confirm />} />
            </Route>
            <Route path="profile" element={<RequireAuth />}>
              <Route path="new" element={<NewProfile />} />
              <Route path="edit" element={<EditProfilePage />} />
            </Route>
            <Route path="v/:code" element={<VoucherLanding />} />
          </Route>
          <Route path="/gallery">
            <Route element={<Layout />}>
              <Route path="join" element={<JoinGallery />} />
              <Route path="new" element={<NewGallery />} />
              <Route element={<RequireAuth />}>
                <Route path="payment" element={<Payment />} />
                <Route path="confirmation" element={<Confirmation />} />
              </Route>
            </Route>
            <Route element={<RequireAuth />}>
              <Route path=":galleryId/:slug" element={<GalleryLayout />}>
                <Route index element={<GalleryPage />} />
                <Route path=":mediaId" element={<GalleryDetailPage />} />
                <Route path="activity" element={<Activity />} />
                <Route path="help" element={<Help />} />
                <Route element={<HostOnly />}>
                  <Route path="settings" element={<Settings />} />
                  <Route path="invite-guests" element={<InviteGuests />} />
                  <Route path="albums" element={<ManageAlbumsPage />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Routes>
        {state?.backgroundLocation && (
          <Routes>
            <Route
              path="/gallery/:galleryId/:slug/:mediaId"
              element={
                <GalleryModal>
                  <GalleryDetailPage isModal />
                </GalleryModal>
              }
            />
          </Routes>
        )}
      </FallbackProvider>
    </>
  )
}

export default Routing
