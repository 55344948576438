import classNames from 'classnames'
import { ReactNode } from 'react'
import { NavLink } from 'react-router-dom'

import styles from './NavItem.module.css'

interface NavItemProps {
  url: string
  Icon: React.ForwardRefExoticComponent<
    Omit<React.SVGProps<SVGSVGElement>, 'ref'>
  >
  iconWidth: number
  iconHeight: number
  label: string
  notificationsComponent?: ReactNode
}

export const NavItem = ({
  url,
  Icon,
  iconWidth,
  iconHeight,
  label,
  notificationsComponent,
}: NavItemProps) => {
  return (
    <li className={styles.item}>
      <NavLink to={url} className={styles.link} end>
        <Icon width={iconWidth} height={iconHeight} />
        <span className={styles.label}>{label}</span>
      </NavLink>
      {notificationsComponent ? notificationsComponent : null}
    </li>
  )
}
