import { GalleryWithMembership } from '@lib/types'
import useSWR from 'swr'

export function useGalleries(url: string | null) {
  const { data, mutate, error } = useSWR<GalleryWithMembership[]>(url)

  const loading = !data && !error

  return {
    error,
    loading,
    galleries: data,
    mutate,
  }
}
