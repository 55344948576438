import { API_URL } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { logoutIntercomMessenger } from '@lib/support'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useSWRConfig } from 'swr'
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/outline'

const LOGOUT_ENDPOINT = `${API_URL}/auth/logout`

import styles from './LogoutButton.module.css'

interface ButtonProps {
  inMenu?: boolean
  listPage?: boolean
}

export const LogoutButton = ({ inMenu, listPage }: ButtonProps) => {
  const navigate = useNavigate()
  const { clearUser } = useUserContext()
  const { mutate } = useSWRConfig()

  const handleLogout = async (ev: React.SyntheticEvent) => {
    ev.preventDefault()
    try {
      await fetchApiData(LOGOUT_ENDPOINT, {
        method: 'DELETE',
      })
      clearUser()
      logoutIntercomMessenger()
      mutate(() => true, undefined, { revalidate: false }) // clear the swr cache
      navigate('/auth/login')
    } catch (err) {
      console.log(err)
      navigate('/')
    }
  }

  const buttonClasses = classNames(styles.logoutButton, {
    [styles.inMenu]: inMenu,
    [styles.listPage]: listPage,
  })

  return (
    <button type="button" onClick={handleLogout} className={buttonClasses}>
      {!inMenu && <ArrowLeftStartOnRectangleIcon width={16} height={16} />}
      <span>Logout</span>
    </button>
  )
}
