import { useUserContext } from '@lib/contexts/user-context'
import { Link } from 'react-router-dom'
import { Headshot } from '@components/Headshot'

import styles from './ProfileNav.module.css'

interface ProfileNavProps {
  galleryUrl: string
}

export const ProfileNav = ({ galleryUrl }: ProfileNavProps) => {
  const { user } = useUserContext()

  return (
    <div aria-haspopup="true" className={styles.profileContainer}>
      <Headshot
        additionalClassnames={styles.headshotDefault}
        photoId={user?.photoUrl}
      />
      <div className={styles.name}>
        <p className="small">
          {user?.firstName} {user?.surname}
        </p>
        <p className="xsmall">
          <Link to="/profile/edit" state={{ galleryUrl }}>
            Edit Profile
          </Link>
        </p>
      </div>
    </div>
  )
}
