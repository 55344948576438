import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { ActionBar } from '@modules/gallery/listing'
import { Alert } from '@components/Alert'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { LoadMore } from '@components/LoadMore'
import { MediaGrid } from '@components/MediaGrid'
import { useMediaList } from '@data/useMediaList'
import { EmptyGallery } from './EmptyGallery'
import { DEFAULT_PAGE_SIZE } from '@lib/constants'
import { useGalleryParamsContext } from '@lib/contexts/gallery-params-context'

import styles from './MediaList.module.css'
import useMediaQuery from '@hooks/useMediaQuery'
import { MobileListView } from './MobileListView'
import { EmptyAlbum } from './EmptyAlbum'

export const MediaList = () => {
  const { galleryId, slug } = useParams()
  const { sort, filter, orderBy, album } = useGalleryParamsContext()
  const isMobile = useMediaQuery('(hover: none) and (max-width: 1000px)')

  const pageSize = DEFAULT_PAGE_SIZE

  const { media, meta, error, loading, currentPage, setCurrentPage } =
    useMediaList(galleryId as string, sort, filter, orderBy, album)

  const handleLoadMore = (ev: React.SyntheticEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    setCurrentPage(currentPage + 1)
  }

  return (
    <div className={styles.galleryContainer}>
      {error && <Alert message={error.message} />}
      <ActionBar />

      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className={styles.galleryContainerInner}>
          {media.length > 0 && !isMobile && (
            <MediaGrid
              media={media}
              pageSize={pageSize}
              galleryId={galleryId as string}
              slug={slug as string}
            />
          )}
          {media.length > 0 && isMobile && <MobileListView media={media} />}
          {media.length === 0 && album === 'all' && <EmptyGallery />}
          {media.length === 0 && album !== 'all' && <EmptyAlbum />}
          {media.length > 0 &&
            meta &&
            meta.total > DEFAULT_PAGE_SIZE &&
            meta.hasNext && <LoadMore handleClick={handleLoadMore} />}
        </div>
      )}
    </div>
  )
}
