import { Outlet } from 'react-router-dom'
import { HeaderLogo } from '@components/HeaderLogo'

import styles from './Layout.module.css'

export const Layout = () => {
  return (
    <>
      <header className={styles.header}>
        <div>
          <HeaderLogo />
        </div>
      </header>
      <main className={styles.main}>
        <Outlet />
      </main>
    </>
  )
}
