import { Heading } from '@components/Heading'

import styles from './SuggestedAlbums.module.css'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { fetchApiData } from '@lib/fetch-api-data'
import { API_URL } from '@lib/constants'
import { useGalleryAlbums } from '@data/useGalleryAlbums'

export const SuggestedAlbums = () => {
  const { galleryId } = useParams()
  const { albums, mutate } = useGalleryAlbums(galleryId as string)
  const [suggested, setSuggested] = useState([
    'Reception',
    'Ceremony',
    'Speeches',
    'First Dance',
  ])

  useEffect(() => {
    const flatAlbums = albums.map((al) => al.name)
    setSuggested(suggested.filter((el) => !flatAlbums.includes(el)))
  }, [JSON.stringify(albums)])

  const handleAddAlbum = async (name: string) => {
    try {
      await fetchApiData(`${API_URL}/albums`, {
        method: 'POST',
        data: { name: name, galleryId },
      })
      mutate()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <>
      {suggested.length > 0 && (
        <div className={styles.container}>
          <Heading h3>Suggested albums</Heading>
          <p>
            Here are some album names that people use, click each on to add to
            your album list or add your own below.
          </p>
          <ul className={styles.albumList}>
            {suggested.map((album) => (
              <li key={album}>
                <button
                  type="button"
                  onClick={() => {
                    handleAddAlbum(album)
                  }}
                >
                  {album}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  )
}
