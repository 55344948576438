import { Alert } from '@components/Alert'
import { Button } from '@components/Button'
import { Field } from '@components/Field'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useQueryParams } from '@hooks/useQueryParams'
import { API_URL } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import React, { useCallback, useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, createSearchParams, Link } from 'react-router-dom'
import styles from './JoinGalleryForm.module.css'

type Inputs = {
  accessCode: string
}

export const JoinGalleryForm: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const queryParams = useQueryParams()
  const ac = queryParams.get('ac')
  const navigate = useNavigate()
  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useUserContext()
  const { register, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      accessCode: ac as string,
    },
  })

  const joinGallery = useCallback(async (accessCode: string) => {
    try {
      setIsLoading(true)

      const JOIN_GALLERY_ENDPOINT = `${API_URL}/galleries/join/${accessCode}`

      const { gallery, slug } = await fetchApiData(JOIN_GALLERY_ENDPOINT, {
        method: 'POST',
      })

      navigate(`/gallery/${gallery}/${slug}`)
    } catch (err) {
      setError('Error when trying to join gallery')
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (ac && user?.isAuthenticated) {
      joinGallery(ac as string)
    }
  }, [ac, joinGallery])

  const onSubmit: SubmitHandler<Inputs> = async ({ accessCode }) => {
    console.log('user', user)
    if (user && user.isAuthenticated) {
      await joinGallery(accessCode)
    } else {
      navigate({
        pathname: '/auth/login',
        search: `?${createSearchParams({ ac: accessCode })}`,
      })
    }
  }

  if (ac && user?.isAuthenticated) {
    return (
      <div className={styles.loading}>
        <LoadingSpinner color="#999" />
      </div>
    )
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {error && <Alert message={error} />}
        <Field
          inputId="code"
          inputType="text"
          label="Gallery Code"
          {...register('accessCode', { required: true })}
        />
        <Button isLoading={isLoading} label="Go" actionLabel="Processing" />
      </form>
      {user?.isAuthenticated && (
        <p>
          Or <Link to="/">return to the gallery selection page</Link>.
        </p>
      )}
    </>
  )
}
