import { useLocation, useNavigate } from 'react-router-dom'
import { Heading } from '@components/Heading'
import { EditProfileForm } from '@modules/profile/EditProfileForm'

import styles from './New.module.css'
import classNames from 'classnames'
import { useEffectOnce } from '@hooks/useEffectOnce'

const EditProfilePage = () => {
  const navigate = useNavigate()
  const location = useLocation()

  useEffectOnce(() => {
    document.title = `Sharetheirday - Create Your Profile`
  })

  const onSuccess = () => {
    return navigate(location.state?.galleryUrl || '/')
  }

  const containerClasses = classNames('innerMain', styles.container)

  return (
    <div className={containerClasses}>
      <Heading h1>Edit your profile</Heading>
      <EditProfileForm onSuccess={onSuccess} />
    </div>
  )
}

export default EditProfilePage
