import { Link, useParams } from 'react-router-dom'
import { useGalleryMetadata } from '@data/useGalleryMetadata'

import styles from './GalleryChangePanel.module.css'
import classNames from 'classnames'

export const GalleryChangePanel = () => {
  const { galleryId } = useParams()
  const { gallery } = useGalleryMetadata(galleryId as string)

  const wrapperClassnames = classNames('xsmall', styles.wrapper)

  return (
    <p className={wrapperClassnames}>
      <span>
        {gallery?.isHost
          ? `You're viewing your gallery (${gallery?.name}).`
          : `You're viewing the gallery for ${gallery?.name}.`}
      </span>{' '}
      <Link to="/">Change gallery</Link>
    </p>
  )
}
