import { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Alert } from '@components/Alert'
import { Button } from '@components/Button'
import { TextArea } from '@components/TextArea'
import { API_URL, VALID_EMAIL_REGEX } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { trackEvent } from '@lib/tracking'
import { Info } from '@components/Info'
import { ErrorMessage } from '@components/ErrorMessage'
import styles from './InviteGuests.module.css'
import { useEffectOnce } from '@hooks/useEffectOnce'

type Inputs = {
  inviteEmailAddresses: string
}

export const InviteGuests = () => {
  const { galleryId } = useParams()
  const { register, handleSubmit, reset } = useForm<Inputs>()
  const { mutate } = useGalleryMetadata(galleryId as string)
  const [validationError, setValidationError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [successMessage, setSuccessMessage] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffectOnce(() => {
    if (galleryId) {
      trackEvent('Invite Guests page view', {
        gallery_id: galleryId,
      })
    }
  })

  const onSubmit: SubmitHandler<Inputs> = async ({ inviteEmailAddresses }) => {
    try {
      setIsSaving(true)
      const emailAddresses = sanitiseEmailAddresses(inviteEmailAddresses)
      if (emailAddresses) {
        const GALLERY_INVITE_ENDPOINT = `${API_URL}/galleries/${galleryId}/invites`

        await fetchApiData(GALLERY_INVITE_ENDPOINT, {
          method: 'POST',
          data: {
            emailAddresses: emailAddresses,
          },
        })
        mutate()
        setIsSaving(false)
        const invites = emailAddresses.length > 1 ? 'invites' : 'invite'
        setSuccessMessage(`${emailAddresses.length} ${invites} sent!`)
        reset()
      }
    } catch (err) {
      setErrorMessage(
        'Something went wrong. Please try again, or contact our team through the help section.'
      )
      setIsSaving(false)
    }
  }

  /**
   * This function sanitises email address invites by
   * - separating email addresses by new line, comma or space;
   * - checks validity of email address format;
   * - and removes duplicate email addresses
   *
   * A validation error is thrown when one or more email addresses are invalid
   *
   */
  const sanitiseEmailAddresses = (
    emailAddresses: string
  ): string[] | undefined => {
    const sanitisedEmailAddresses = emailAddresses.split(/\s*,\s*|\s+/)
    const checkEmailAddressesAreValid = sanitisedEmailAddresses.every((email) =>
      VALID_EMAIL_REGEX.test(email)
    )

    if (!checkEmailAddressesAreValid) {
      setValidationError(
        'You have entered one or more invalid email addresses. Please double check your entries and try again.'
      )
    } else {
      setValidationError('')
      const dedupedEmailAddresses = new Set(sanitisedEmailAddresses)
      return Array.from(dedupedEmailAddresses)
    }
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {validationError && <Alert message={validationError} />}
      {successMessage && <Info message={successMessage} />}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <label>
        <span className="sr-only">Guest email addresses</span>
        <TextArea
          inputId="invite-guests"
          rows={10}
          {...register('inviteEmailAddresses')}
        />
      </label>

      <Button
        isLoading={isSaving}
        label="Send invites"
        actionLabel="Sending..."
      />
    </form>
  )
}
