import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { MoreMenu } from '@components/MoreMenu'
import { useGalleryActivity } from '@data/useGalleryActivity'
import { NotificationsCount } from '@components/NotificationsCount'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { UploadButton } from '@components/UploadButton'
import {
  PhotoIcon,
  ChatBubbleLeftIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/outline'

import styles from './FooterNav.module.css'
import { useMoreMenuContext } from '@lib/contexts/more-menu-context'

export const FooterNav = () => {
  const { galleryId, slug } = useParams()
  const { gallery } = useGalleryMetadata(galleryId as string)
  const { showMoreMenu, moreMenuShouldShow } = useMoreMenuContext()
  const { notifications } = useGalleryActivity(galleryId as string)

  const handleMoreClick = () => {
    moreMenuShouldShow((prevState) => !prevState)
  }

  const galleryLink = `/gallery/${galleryId}/${slug}`

  return (
    <nav className={styles.nav}>
      <ul>
        <li>
          <Link
            to={galleryLink}
            onClick={() => {
              moreMenuShouldShow(false)
            }}
          >
            <span className={styles.icon}>
              <PhotoIcon width="22" height="22" />
            </span>
            Gallery
          </Link>
        </li>
        <li>
          <UploadButton isFooter />
        </li>
        <li style={{ position: 'relative' }}>
          <Link
            to={`${galleryLink}/activity`}
            onClick={() => {
              moreMenuShouldShow(false)
            }}
          >
            <span className={styles.icon}>
              <ChatBubbleLeftIcon width="22" height="22" />
            </span>
            Activity
          </Link>
          {notifications.length > 0 && (
            <NotificationsCount count={notifications.length} />
          )}
        </li>
        <li>
          <button type="button" onClick={handleMoreClick}>
            <span className={styles.icon}>
              <EllipsisHorizontalIcon width="22" height="22" />
            </span>
            More
          </button>
          {showMoreMenu && (
            <MoreMenu
              showMoreMenu={moreMenuShouldShow}
              galleryLink={galleryLink}
              isHost={gallery?.isHost}
            />
          )}
        </li>
      </ul>
    </nav>
  )
}
