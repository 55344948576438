import classNames from 'classnames'
import * as React from 'react'
import styles from './Field.module.css'

export interface FieldProps {
  label: string
  inputId: string
  inputType?: 'email' | 'text' | 'password' | 'hidden'
  className?: string
  disabled?: boolean
  additionalInfo?: string
  placeholder?: string
  errorMessage?: string
}

export const Field = React.forwardRef<HTMLInputElement, FieldProps>(
  (
    {
      label,
      inputId,
      inputType = 'text',
      className = '',
      additionalInfo,
      placeholder,
      errorMessage,
      ...rest
    },
    ref
  ) => {
    if (inputType === 'hidden') {
      return <input type={inputType} ref={ref} {...rest} />
    }
    const fieldClassnames = classNames(styles.field, className)
    return (
      <div className={fieldClassnames}>
        <label htmlFor={inputId}>
          <span>
            {label}
            {additionalInfo && <span>{additionalInfo}</span>}
          </span>
          <input
            data-testid={inputId}
            type={inputType}
            className={styles.field}
            ref={ref}
            placeholder={placeholder}
            {...rest}
          />
        </label>
        <div className={styles.errors}>{errorMessage}</div>
      </div>
    )
  }
)
