import { useUserContext } from '@lib/contexts/user-context'
import { trackEvent } from '@lib/tracking'
import { useEffect, useState } from 'react'
import { PanelCard } from './PanelCard'
import { PanelPaypal } from './PanelPaypal'
import { CreditCardIcon } from '@heroicons/react/24/outline'
import styles from './PaymentOptions.module.css'

const CARD_ID = 'card'
const PAYPAL_ID = 'paypal'

export const PaymentOptions = () => {
  const [currentTab, setCurrentTab] = useState(CARD_ID)
  const { user } = useUserContext()

  const handleTabClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setCurrentTab(ev.currentTarget.id)
  }

  useEffect(() => {
    if (user && user.currentGallery) {
      trackEvent('Gallery Payment page view', {
        gallery_id: user.currentGallery,
      })
    }
  }, [user])

  return (
    <div className={styles.paymentOptions}>
      <div role="tablist" aria-label="Payment options" className={styles.tabs}>
        <button
          role="tab"
          aria-selected={currentTab === CARD_ID ? 'true' : 'false'}
          aria-controls={`${CARD_ID}-tab`}
          id={CARD_ID}
          onClick={handleTabClick}
        >
          <CreditCardIcon
            width={25}
            height={25}
            className="colorize-independence card"
          />
          <span>Credit/Debit Card</span>
        </button>
        <button
          role="tab"
          aria-selected={currentTab === PAYPAL_ID ? 'true' : 'false'}
          aria-controls={`${PAYPAL_ID}-tab`}
          id={PAYPAL_ID}
          onClick={handleTabClick}
        >
          <img
            src="/img/logo-paypal.svg"
            alt="PayPal"
            width="102"
            height="27"
          />
        </button>
      </div>
      {currentTab === CARD_ID && (
        <div
          tabIndex={currentTab === CARD_ID ? 0 : -1}
          role="tabpanel"
          id={`${CARD_ID}-tab`}
          aria-labelledby={CARD_ID}
          className={styles['tab-panel']}
          hidden={currentTab === CARD_ID ? false : true}
        >
          <PanelCard />
        </div>
      )}
      {currentTab === PAYPAL_ID && (
        <div
          tabIndex={currentTab === PAYPAL_ID ? 0 : -1}
          role="tabpanel"
          id={`${PAYPAL_ID}-tab`}
          aria-labelledby={PAYPAL_ID}
          className={styles['tab-panel']}
          hidden={currentTab === PAYPAL_ID ? false : true}
        >
          <PanelPaypal />
        </div>
      )}
    </div>
  )
}
