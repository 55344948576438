import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { ErrorMessage } from '@components/ErrorMessage'
import { GallerySettingsForm } from '@modules/gallery/settings'
import { Heading } from '@components/Heading'
import { useEffect } from 'react'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { trackEvent } from '@lib/tracking'
import { useHandleGalleryError } from '@hooks/useHandleGalleryError'

const Settings = () => {
  const { galleryId } = useParams()
  const { gallery, loading, error } = useGalleryMetadata(galleryId as string)

  useHandleGalleryError(galleryId)

  useEffect(() => {
    if (gallery) {
      document.title = `Settings - ${gallery?.name} Gallery - Sharetheirday`
    }
  }, [gallery])

  useEffectOnce(() => {
    if (galleryId) {
      trackEvent('Gallery Settings page view', {
        gallery_id: galleryId,
      })
    }
  })

  return (
    <>
      {error && <ErrorMessage>Error loading gallery data</ErrorMessage>}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="innerMain">
          <Heading h1>Gallery Settings</Heading>
          <GallerySettingsForm />
        </div>
      )}
    </>
  )
}

export default Settings
