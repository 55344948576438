import { Alert } from '@components/Alert'
import { Button } from '@components/Button'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeCardElementChangeEvent } from '@stripe/stripe-js'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './StripeForm.module.css'

interface StripeFormProps {
  clientSecret: string
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325D',
      fontWeight: 500,
      fontFamily:
        'Source Sans Pro, Helvetica Neue, Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: '#CFD7DF',
      },
    },
    invalid: {
      color: '#E25950',
    },
  },
}

const STRIPE_ERROR_MESSAGE =
  'There was a problem contacting our payment system, please try again.'

export const StripeForm = ({ clientSecret }: StripeFormProps) => {
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState<string>()
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [isComplete, setIsComplete] = useState<boolean>(false)

  const handleSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault()
    setIsSaving(true)

    if (!stripe || !elements) {
      return
    }

    try {
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          card: elements.getElement(CardElement)!,
        },
      })

      if (result.error) {
        if (result.error.payment_intent?.status === 'succeeded') {
          // show error asking to contact support
          setError(
            'There was a problem processing your payment. Please contact help@sharetheirday.com and we will get back to you as soon as possible.'
          )
          setIsSaving(false)
        } else {
          setError(result.error.message)
          setIsSaving(false)
        }
      } else {
        if (result.paymentIntent.status === 'succeeded') {
          navigate('/gallery/confirmation')
        }
      }
    } catch (err) {
      setError(STRIPE_ERROR_MESSAGE)
      setIsSaving(false)
      console.log(err)
    }
  }

  const handleCardElementChange = (ev: StripeCardElementChangeEvent) => {
    setIsComplete(ev.complete)
    setError(ev.error ? ev.error.message : '')
  }

  return (
    <form onSubmit={handleSubmit}>
      {error && <Alert message={error} />}
      <div className={styles.cardContainer}>
        <CardElement
          options={CARD_ELEMENT_OPTIONS}
          onChange={handleCardElementChange}
        />
      </div>
      <Button
        type="submit"
        label="Pay now"
        actionLabel="Processing"
        isLoading={isSaving}
        disabled={isSaving || !isComplete}
      />
    </form>
  )
}
