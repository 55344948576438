import { Heading } from '@components/Heading'
import { AddAlbum, ListAlbums } from '@modules/gallery'
import { SuggestedAlbums } from '@modules/gallery/albums/SuggestedAlbums'

export const ManageAlbumsPage = () => {
  return (
    <div className="innerMain">
      <Heading h1>Albums</Heading>
      <p>
        Manage the albums that you and your guests can use to organise photos
        and videos.
      </p>
      <SuggestedAlbums />
      <AddAlbum />
      <ListAlbums />
    </div>
  )
}
