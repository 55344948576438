import { API_URL } from '@lib/constants'
import { GalleryActivity } from '@lib/types'
import useSWR from 'swr'

export function useGalleryActivity(galleryId: string) {
  const ENDPOINT = `${API_URL}/galleries/${galleryId}/activity`

  const { data, error, mutate } = useSWR<GalleryActivity>(ENDPOINT)

  const loading = !data && !error

  return {
    error,
    loading,
    notifications: data?.data || [],
    mutate,
  }
}
