import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ActivityNotification, ActivityType } from '@lib/types'
import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import { timeAgo } from '@lib/date-utils'
import { API_URL, CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryActivity } from '@data/useGalleryActivity'
import { XCircleIcon } from '@heroicons/react/24/outline'
import styles from './ActivityItem.module.css'

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

const DEFAULT_NAMED_TRANSFORMATION =
  't_media_lib_thumb_auto_format' as unknown as NamedTransformationAction

export const ActivityItem = ({
  id,
  originator,
  timestamp,
  type,
  mediaId,
}: ActivityNotification) => {
  const { galleryId, slug } = useParams()
  const navigate = useNavigate()
  const [isClearing, setIsClearing] = useState(false)
  const { mutate } = useGalleryActivity(galleryId as string)

  const mediaPath = `/gallery/${galleryId}/${slug}/${mediaId}`

  const handleClearNotification = async (id: string | string[]) => {
    try {
      setIsClearing(true)
      const CLEAR_NOTIFICATION_ENDPOINT = `${API_URL}/activities/notifications`
      const ids = Array.isArray(id) ? id : [id]
      await fetchApiData(CLEAR_NOTIFICATION_ENDPOINT, {
        method: 'PUT',
        data: {
          ids,
        },
      })
      mutate()
      setIsClearing(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleNavigate = (ev: React.SyntheticEvent<HTMLAnchorElement>) => {
    ev.preventDefault()
    navigate(mediaPath)
  }

  const behaviour =
    type === ActivityType.comment
      ? `commented on a photo`
      : `uploaded a new photo`

  const thumbnailUrl = `${cldInstance
    .image(`${galleryId}/${mediaId}`)
    .namedTransformation(DEFAULT_NAMED_TRANSFORMATION)
    .toURL()}`

  return (
    <li key={id} className={styles.item}>
      <a href={mediaPath} onClick={handleNavigate}>
        <span className={styles.thumbnail}>
          <img src={thumbnailUrl} alt="" />
        </span>
        <p>
          {originator.firstName} {originator.surname} {behaviour}
          <span>{timeAgo(Date.now() - Date.parse(timestamp))}</span>
        </p>
      </a>
      <span className={styles['button-holder']}>
        <button
          onClick={() => {
            handleClearNotification(id)
          }}
        >
          {isClearing ? (
            <LoadingSpinner small color="#e5cbb4" />
          ) : (
            <XCircleIcon
              width={20}
              height={20}
              className="colorize-desert-sand"
            />
          )}
        </button>
      </span>
    </li>
  )
}
