import { getAnalyticsObject } from './getAnalyticsObject'

export const identifyUser = (
  userId: string,
  email: string,
) => {
  const analytics = getAnalyticsObject()

  if (!analytics) {
    return
  }

  analytics.identify(userId, {
    email,
  })
}