import { Link, Outlet, useNavigate } from 'react-router-dom'
import { HeaderLogo } from '@components/HeaderLogo'

import styles from './LegalLayout.module.css'

export const LegalLayout = () => {
  const navigate = useNavigate()
  return (
    <>
      <header className={styles.header}>
        <span className={styles.backlink}>
          <Link
            to=".."
            onClick={(e) => {
              e.preventDefault()
              navigate(-1)
            }}
          >
            &laquo; Back
          </Link>
        </span>
        <div>
          <HeaderLogo />
        </div>
      </header>
      <main className={styles.main}>
        <Outlet />
      </main>
    </>
  )
}
