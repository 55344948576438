import { API_URL, DEFAULT_META, DEFAULT_PAGE_SIZE } from '@lib/constants'
import { GalleryMedia, Media, Pageable } from '@lib/types'
import { fetcher } from '@modules/SWRConfigProvider'
import useSWRInfinite from 'swr/infinite'

const getMediaFromData = (data: GalleryMedia[] | undefined): Media[] => {
  if (!data || !data.length) return []

  return data.map((d) => d.data).flat()
}

const getMetaFromData = (data: GalleryMedia[] | undefined): Pageable => {
  if (!data || !data.length) return DEFAULT_META
  const lastItem = data[data.length - 1]
  return lastItem._meta
}

export function useMediaList(
  galleryId: string,
  sort: string,
  filter: string,
  orderBy: string,
  album: string
) {
  const ENDPOINT = `${API_URL}/galleries/${galleryId}/media`

  const getKey = (pageIndex: number) => {
    if (!galleryId) return null
    let url = `${ENDPOINT}?limit=${DEFAULT_PAGE_SIZE}&sort=${sort}&filter=${filter}&orderBy=${orderBy}&page=${
      pageIndex + 1
    }`

    if (album && album !== 'all') {
      url = url + `&album=${album}`
    }

    return url
  }

  const { data, error, mutate, size, setSize, isValidating } =
    useSWRInfinite<GalleryMedia>(getKey, fetcher)

  const loading = !data && !error

  const media = getMediaFromData(data)
  const meta = getMetaFromData(data)

  return {
    error,
    loading,
    media,
    meta,
    currentPage: size,
    setCurrentPage: setSize,
    mutate,
    isValidating,
  }
}
