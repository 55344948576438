import classNames from 'classnames'
import styles from './Heading.module.css'

export interface HeadingProps {
  children: React.ReactNode
  h1?: boolean
  h1Large?: boolean
  h2?: boolean
  h3?: boolean
  h4?: boolean
  className?: string
  id?: string
}

export const Heading: React.FC<React.PropsWithChildren<HeadingProps>> = ({
  h1,
  h1Large,
  h2,
  h3,
  h4,
  className,
  children,
  id,
}) => {
  if (h1) {
    const cx = classNames(styles.h1, className)
    return (
      <h1 className={cx} id={id}>
        {children}
      </h1>
    )
  }

  if (h1Large) {
    const cx = classNames(styles.h1Large, className)
    return (
      <h1 className={cx} id={id}>
        {children}
      </h1>
    )
  }

  if (h2) {
    const cx = classNames(styles.h2, className)
    return (
      <h2 className={cx} id={id}>
        {children}
      </h2>
    )
  }

  if (h3) {
    const cx = classNames(styles.h3, className)
    return (
      <h3 className={cx} id={id}>
        {children}
      </h3>
    )
  }

  if (h4) {
    const cx = classNames(styles.h4, className)
    return (
      <h4 className={cx} id={id}>
        {children}
      </h4>
    )
  }

  return <p>{children}</p>
}
