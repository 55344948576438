import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useNavigate, useParams } from 'react-router-dom'
import { ModalContainer } from './ModalContainer'

interface GalleryModalProps {
  children: React.ReactNode
}

export const GalleryModal = ({ children }: GalleryModalProps) => {
  const { galleryId, slug } = useParams()
  const navigate = useNavigate()
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  const handleClose = () => {
    navigate(`/gallery/${galleryId}/${slug}`)
  }

  return isBrowser
    ? ReactDOM.createPortal(
        <ModalContainer handleClose={handleClose} content={children} />,
        document.getElementById('modal-root') as HTMLElement
      )
    : null
}
