import { MediaCard } from '@components/MediaCard'
import { Media as MediaType } from '@lib/types'

interface MobileListViewProps {
  media?: MediaType[]
}

export const MobileListView = ({ media }: MobileListViewProps) => {
  return (
    <ul>
      {media?.map((media) => (
        <li key={media.id}>
          <MediaCard data={media} />
        </li>
      ))}
    </ul>
  )
}
