import * as React from 'react'
import { Heading } from '@components/Heading'
import styles from './RadioGroup.module.css'

export interface RadioOption {
  value: string
  label: string
}

interface RadioGroupProps {
  options: RadioOption[]
  label: string
}

export const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(
  ({ options, label, ...rest }, ref) => {
    return (
      <div className="radioGroup">
        <Heading h3>{label}</Heading>
        <div className={styles.options}>
          {options.map((option) => (
            <label key={option.value}>
              <input type="radio" value={option.value} ref={ref} {...rest} />
              {option.label}
            </label>
          ))}
        </div>
      </div>
    )
  }
)
