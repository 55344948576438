import { Heading } from '@components/Heading'

export const YourProfile = () => {
  return (
    <>
      <section>
        <Heading h3>How do I upload my profile image?</Heading>
        <p>
          So that people can associate a name with a face (we&apos;ve all been
          there after a couple of glasses of bubbles at a party!), you can
          upload a photo to your profile.
        </p>

        <p>
          This&apos;ll show next to your name anywhere you leave a comment, and
          in the activity updates that other guests get.
        </p>
        <ul>
          <li>
            To change your profile photo click on Edit Profile under your name
            in the main navigation panel on the left (or tap the More button in
            the footer menu, and tap Edit Profile).
          </li>

          <li>
            If you&apos;ve not uploaded a photo yet click on the Upload button
            in the Profile Photo section, or if you already have one but would
            like to change it click on the Change button.
          </li>

          <li>
            Select a new photo, then choose the crop you&apos;d like to use. We
            show a thumbnail example of what it&apos;ll look like to help you
            choose.
          </li>

          <li>
            When you&apos;re happy with the crop, click the save button at the
            bottom of the page.
          </li>
        </ul>

        <p>
          You can change your profile photo at any time, and we suggest one with
          no sunglasses and your lovely smile on show!
        </p>
      </section>
    </>
  )
}
