export const getShortDateString = (selectedDate: Date) => {
  // returns YYYY-MM-DD, accounting for timezone
  const dateWithTimezoneOffset = new Date(selectedDate.setMinutes(
    selectedDate.getMinutes() - selectedDate.getTimezoneOffset()
  ))
  return dateWithTimezoneOffset.toISOString().slice(0, 10)
}

export const getDateString = (selectedDate: Date, includeOrdinal = false) => {
  // returns August 7, 2022 or August 7th, 2022
  const month = selectedDate.toLocaleString('default', { month: 'long' })
  const day = selectedDate.toLocaleString('default', { day: 'numeric' })
  const year = selectedDate.toLocaleString('default', { year: 'numeric' })
  const ordinal = includeOrdinal ? getOrdinal(selectedDate.getDate()) : ''
  return `${month} ${day}${ordinal}, ${year}`
}

const getOrdinal = (d: number) => {
  if (d > 3 && d < 21) return 'th'
  switch (d % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

const timeAgoLocale = {
  en: {
    moment: 'a moment ago',
    moments: 'moments ago',
    seconds: '%s seconds ago',
    minute: 'a minute ago',
    minutes: '%m minutes ago',
    hour: 'an hour ago',
    hours: '%h hours ago',
    day: 'a day ago',
    days: '%D days ago',
    week: 'a week ago',
    weeks: '%w weeks ago',
    month: 'a month ago',
    months: '%M months ago',
    years: 'more than a year ago',
    never: 'never',
  },
}

const MOMENTS = 2
const SECONDS = 5
const MINUTE = 60
const HOUR = 60 * MINUTE
const DAY = 24 * HOUR
const WEEK = 7 * DAY
const MONTH = 30 * DAY
const YEAR = 365 * DAY
// workaround for when `ms = Date.now() - 0`
const NEVER = 45 * YEAR

export const timeAgo = (ms: number, locale = timeAgoLocale.en) => {
  let ago = Math.floor(ms / 1000)
  let part = 0

  if (ago < MOMENTS) {
    return locale.moment
  }
  if (ago < SECONDS) {
    return locale.moments
  }
  if (ago < MINUTE) {
    return locale.seconds.replace(/%\w?/, ago.toString())
  }

  if (ago < 2 * MINUTE) {
    return locale.minute
  }
  if (ago < HOUR) {
    while (ago >= MINUTE) {
      ago -= MINUTE
      part += 1
    }
    return locale.minutes.replace(/%\w?/, part.toString())
  }

  if (ago < 2 * HOUR) {
    return locale.hour
  }
  if (ago < DAY) {
    while (ago >= HOUR) {
      ago -= HOUR
      part += 1
    }
    return locale.hours.replace(/%\w?/, part.toString())
  }

  if (ago < 2 * DAY) {
    return locale.day
  }
  if (ago < WEEK) {
    while (ago >= DAY) {
      ago -= DAY
      part += 1
    }
    return locale.days.replace(/%\w?/, part.toString())
  }

  if (ago < 2 * WEEK) {
    return locale.week
  }
  if (ago < MONTH) {
    while (ago >= WEEK) {
      ago -= WEEK
      part += 1
    }
    return locale.weeks.replace(/%\w?/, part.toString())
  }

  if (ago < 2 * MONTH) {
    return locale.month
  }
  if (ago < YEAR) {
    // 45 years, approximately the epoch
    while (ago >= MONTH) {
      ago -= MONTH
      part += 1
    }
    return locale.months.replace(/%\w?/, part.toString())
  }

  if (ago < NEVER) {
    return locale.years
  }

  return locale.never
}
