import React, { ChangeEvent } from 'react'
import styles from './TextArea.module.css'

interface TextAreaProps {
  label?: string
  placeholder?: string
  inputId: string
  rows: number
  name?: string
  value?: string
  onChange?: (ev: React.ChangeEvent<HTMLTextAreaElement>) => void
  className?: string
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ placeholder, inputId, label, className = '', rows = 1, ...rest }, ref) => {
    return (
      <div className={className}>
        <label htmlFor={inputId}>
          {label && <span>{label}</span>}
          <textarea
            className={styles.textarea}
            placeholder={placeholder}
            rows={rows}
            id={inputId}
            ref={ref}
            {...rest}
          />
        </label>
      </div>
    )
  }
)
