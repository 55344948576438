import classNames from 'classnames'
import * as React from 'react'
import styles from './ButtonAsLink.module.css'

interface ButtonAsLinkProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  alternateStyle?: boolean
}

export const ButtonAsLink: React.FC<React.PropsWithChildren<ButtonAsLinkProps>> = ({
  onClick,
  children,
  alternateStyle = false,
}) => {
  const className = classNames(styles.buttonAsLink, {
    [styles.buttonAsLinkAlternate]: alternateStyle,
  })
  return (
    <button className={className} type="submit" onClick={onClick}>
      {children}
    </button>
  )
}
