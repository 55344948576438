import classNames from 'classnames'
import styles from './ProgressItem.module.css'

interface ProgressItemProps {
  label: string
  step: number
  isCurrent: boolean
}

export const ProgressItem = ({ label, isCurrent, step }: ProgressItemProps) => {
  const classes = classNames(styles.item, {
    [styles.current]: isCurrent,
    [styles.beforeSeparator]: step !== 1,
  })
  return (
    <li aria-current={isCurrent ? 'step' : undefined} className={classes}>
      <span />
      {label}
    </li>
  )
}
