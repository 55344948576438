import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import { Heading } from '@components/Heading'
import { CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import { getDateString, getShortDateString } from '@lib/date-utils'
import classNames from 'classnames'

import styles from './GalleryNameAndImage.module.css'

const DEFAULT_NAMED_TRANSFORMATION =
  't_gallery_profile' as unknown as NamedTransformationAction

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

interface GalleryNameAndImageProps {
  name: string
  eventDate: Date
  imageUrl?: string
  size?: 'small' | 'regular' | 'large'
}

const replaceAndWithAmpersand = (name?: string) => {
  if (!name) return null
  const nameArr = name.split('and')
  const result = [
    nameArr[0],
    <span className="ampersand" key="amp">
      &
    </span>,
    nameArr[1],
  ]
  return result
}

export const GalleryNameAndImage = ({
  name,
  eventDate,
  size = 'large',
  imageUrl,
}: GalleryNameAndImageProps) => {
  const containerStyles = classNames(styles.names, {
    [styles.regular]: size === 'regular',
    [styles.small]: size === 'small',
  })
  const galleryPhotoUrl = imageUrl
    ? `${cldInstance
        .image(`gallery-profile/${imageUrl}`)
        .namedTransformation(DEFAULT_NAMED_TRANSFORMATION)
        .toURL()}`
    : null
  return (
    <div className={containerStyles}>
      <span className={styles.profileImage}>
        {galleryPhotoUrl ? (
          <img src={galleryPhotoUrl} alt="" />
        ) : (
          <span className={styles.icon}>
            <img
              src="/img/stdy-icon.svg"
              alt=""
              className="colorize-alice-blue"
              width="50"
              height="50"
            />
          </span>
        )}
      </span>
      <div className={styles.text}>
        <Heading h1Large={size === 'large'} h1={size !== 'large'}>
          {replaceAndWithAmpersand(name)}
        </Heading>
        {size !== 'small' && (
          <time dateTime={getShortDateString(eventDate)}>
            {getDateString(eventDate, true)}
          </time>
        )}
      </div>
    </div>
  )
}
