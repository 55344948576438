import { Link } from 'react-router-dom'
import { HeaderLogo } from '@components/HeaderLogo'
import { LogoutButton } from '@components/LogoutButton'

import styles from './MoreMenu.module.css'

interface MoreMenuProps {
  galleryLink: string
  showMoreMenu: (res: boolean) => void
  isHost?: boolean
}

export const MoreMenu = ({
  galleryLink,
  showMoreMenu,
  isHost,
}: MoreMenuProps) => {
  return (
    <ul className={styles.menu}>
      <li>
        <HeaderLogo />
      </li>
      {isHost && (
        <>
          <li>
            <Link
              to={`${galleryLink}/invite-guests`}
              onClick={() => {
                showMoreMenu(false)
              }}
            >
              Invite Guests
            </Link>
          </li>
          <li>
            <Link
              to={`${galleryLink}/albums`}
              onClick={() => {
                showMoreMenu(false)
              }}
            >
              Albums
            </Link>
          </li>
          <li>
            <Link
              to={`${galleryLink}/settings`}
              onClick={() => {
                showMoreMenu(false)
              }}
            >
              Gallery Settings
            </Link>
          </li>
        </>
      )}
      <li>
        <Link
          to={`${galleryLink}/help`}
          onClick={() => {
            showMoreMenu(false)
          }}
        >
          Help Center
        </Link>
      </li>
      <li>
        <Link
          to="/profile/edit"
          state={{ galleryUrl: galleryLink }}
          onClick={() => {
            showMoreMenu(false)
          }}
        >
          Edit Profile
        </Link>
      </li>
      <li>
        <Link
          to="/"
          onClick={() => {
            showMoreMenu(false)
          }}
        >
          Change gallery
        </Link>
      </li>
      <li>
        <LogoutButton inMenu />
      </li>
    </ul>
  )
}
