import { LoadingSpinner } from '@components/LoadingSpinner'
import classNames from 'classnames'
import * as React from 'react'
import styles from './Button.module.css'

interface ButtonProps {
  label: string
  actionLabel?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  type?: 'submit' | 'button'
  isLoading?: boolean
  disabled?: boolean
  className?: string
  children?: React.ReactNode
}

export const Button = ({
  label,
  actionLabel,
  onClick,
  type = 'submit',
  isLoading = false,
  disabled = false,
  className,
}: ButtonProps) => (
  <button
    className={classNames(styles.button, className)}
    type={type}
    onClick={onClick}
    disabled={disabled || isLoading}
  >
    <span className={styles.buttonInner}>
      {isLoading ? (
        <>
          {actionLabel}
          <LoadingSpinner small />
        </>
      ) : (
        label
      )}
    </span>
  </button>
)
