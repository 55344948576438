import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import { CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import { Headshot } from '@components/Headshot'

import styles from './NameAndProfilePhoto.module.css'

const DEFAULT_NAMED_TRANSFORMATION =
  't_gallery_profile' as unknown as NamedTransformationAction

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

interface NameAndProfilePhotoProps {
  photoId?: string | null
  name?: string
}

export const NameAndProfilePhoto = ({
  photoId,
  name,
}: NameAndProfilePhotoProps) => {
  return (
    <div className={styles.person}>
      <Headshot photoId={photoId} height={24} width={24} />
      <p>{name}</p>
    </div>
  )
}
