import { useEffect, useState } from 'react'
import { SubmitHandler, Controller, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { Alert } from '@components/Alert'
import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { Heading } from '@components/Heading'
import { TextArea } from '@components/TextArea'
import { API_URL } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { getErrorMessage } from '@lib/get-error-message'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { useUserContext } from '@lib/contexts/user-context'
import { Info } from '@components/Info'
import styles from './GallerySettingsForm.module.css'
import { EditProfilePhoto } from '@components/EditProfilePhoto/EditProfilePhoto'
import { ClassNames, DayPicker } from 'react-day-picker'
import datePickerStyles from 'react-day-picker/dist/style.module.css'
import { getShortDateString } from '@lib/date-utils'
import { Field } from '@components/Field'

type Inputs = {
  name: string
  message: string
  socialShareAllowed: boolean
  eventDate: Date
}

interface Payload {
  name: string
  message: string
  socialShareAllowed: boolean
  eventDate: string
  photoUrl?: string
}

export const GallerySettingsForm = () => {
  const { galleryId } = useParams()
  const { user } = useUserContext()
  const { gallery, mutate } = useGalleryMetadata(galleryId as string)
  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState<string>()
  const [successMessage, setSuccessMessage] = useState<string>()
  const [galleryPhoto, setGalleryPhoto] = useState<string | null>(null)

  const { register, control, handleSubmit } = useForm<Inputs>({
    defaultValues: {
      name: gallery?.name || undefined,
      message: gallery?.message || undefined,
      socialShareAllowed: gallery?.socialShareAllowed,
      eventDate: gallery?.eventDate,
    },
  })

  useEffect(() => {
    if (gallery && gallery.photoUrl) {
      setGalleryPhoto(gallery.photoUrl)
    }
  }, [gallery])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsSaving(true)
      const { message, socialShareAllowed, eventDate, name } = data
      const payload: Payload = {
        name,
        message,
        socialShareAllowed,
        eventDate: getShortDateString(eventDate),
      }

      if (galleryPhoto) {
        payload.photoUrl = galleryPhoto
      }
      const ENDPOINT_URL = `${API_URL}/galleries/${gallery?.id}`
      await fetchApiData(ENDPOINT_URL, {
        data: payload,
        method: 'PUT',
      })
      mutate()
      setIsSaving(false)
      setSuccessMessage('Saved!')
    } catch (err) {
      setSaveError(getErrorMessage(err))
      setIsSaving(false)
    }
  }

  const datePickerClassNames: ClassNames = {
    ...datePickerStyles,
  }

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {saveError && <Alert message={saveError} />}
      {successMessage && <Info message={successMessage} />}

      <Heading h2>Gallery name</Heading>
      <Field
        inputId="gallery-name"
        inputType="text"
        label="Change your gallery name"
        {...register('name', {
          required: true,
        })}
      />

      <EditProfilePhoto
        title="Gallery Photo"
        description="We show this at the top of the gallery and next to your comments."
        savedPhoto={galleryPhoto}
        setSavedPhoto={setGalleryPhoto}
        folder="gallery-profile"
        deleteEndpoint={`${API_URL}/galleries/${galleryId}/profile-photo`}
        mutate={mutate}
        isGalleryPhoto
      />

      {gallery?.isHost && (
        <>
          <Heading h2>Wedding date</Heading>
          <p>Change the date of your wedding here.</p>
          <Controller
            control={control}
            name="eventDate"
            render={({ field }) => (
              <DayPicker
                defaultMonth={
                  field.value &&
                  new Date(field.value.getFullYear(), field.value.getMonth())
                }
                selected={field.value}
                classNames={datePickerClassNames}
                styles={{
                  caption: {
                    color: 'var(--color-independence)',
                    fontWeight: 'normal',
                  },
                }}
                modifiersStyles={{
                  selected: {
                    backgroundColor: 'var(--color-independence)',
                  },
                }}
                onDayClick={(newDate: Date | undefined) => {
                  field.onChange(newDate)
                }}
              />
            )}
          />
        </>
      )}

      <Heading h2>Guest welcome message</Heading>
      <label>
        <p>Add a welcome message for your guests (optional)</p>
        <TextArea inputId="gallery-message" rows={3} {...register('message')} />
      </label>

      <Heading h2>Social Sharing</Heading>

      <p>
        Your guests will see a message on the gallery asking them not to share
        on social media until you enable it.
      </p>
      <Checkbox
        className={styles.socialShare}
        inputId="socialShare"
        label="Enable social sharing"
        {...register('socialShareAllowed', { required: false })}
      />

      <Button isLoading={isSaving} label="Save" actionLabel="Saving..." />
    </form>
  )
}
