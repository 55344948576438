import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from '@components/Button'
import { Heading } from '@components/Heading'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useUserContext } from '@lib/contexts/user-context'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import styles from './Confirmation.module.css'
import { Alert } from '@components/Alert'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { Progress } from '@components/Progress'
import { trackEvent } from '@lib/tracking'

const Confirmation = () => {
  const navigate = useNavigate()
  const { user } = useUserContext()
  const [timeoutError, setTimeoutError] = useState(false)
  const currentGallery = user && user.currentGallery
  const timer = useRef<NodeJS.Timer | null>(null)

  const { gallery, loading, error } = useGalleryMetadata(currentGallery, 5)

  useEffectOnce(() => {
    document.title = `Sharetheirday - Payment details`
  })

  useEffect(() => {
    if (!user?.isCreatingGallery) {
      navigate('/')
    }
  }, [user])

  const handleClick = () => {
    if (gallery) {
      navigate(`/gallery/${gallery.id}/${gallery.slug}`)
    }
  }

  useEffectOnce(() => {
    timer.current = setTimeout(() => {
      setTimeoutError(true)
    }, 30_000)

    return () => {
      if (timer.current) {
        clearTimeout(timer.current)
      }
    }
  })

  if (gallery && timer.current) {
    if (user && user.currentGallery) {
      trackEvent('Gallery Confirmation page view', {
        gallery_id: gallery.id,
      })
    }

    clearTimeout(timer.current)
  }

  return (
    <>
      <Progress currentPage={3} />

      {(loading || !gallery) && !timeoutError && (
        <div className={styles.processing}>
          <p>Setting up your gallery...</p>
          <p>
            Please don't refresh this page or click back, we'll have it ready
            soon!
          </p>
          <LoadingSpinner color="#cfcfcf" />
        </div>
      )}
      {timeoutError && (
        <Alert message="There was a problem processing your payment. Please contact help@sharetheirday.com and we'll get that fixed for you." />
      )}
      {gallery && !error && !timeoutError && (
        <div className="innerMain">
          <Heading h1>Thank you!</Heading>

          <div className={styles.confirmationMessage}>
            <p>
              We&apos;re excited for you to see all the memories captured by
              your guests on your special day!
            </p>

            <p>A receipt for your purchase will arrive via email in a jiffy.</p>
          </div>
          <div className={styles.buttonContainer}>
            <Button onClick={handleClick} label="Let's go to the gallery!" />
          </div>
        </div>
      )}
    </>
  )
}

export default Confirmation
