import { LoadingSpinner } from '@components/LoadingSpinner'
import { useMediaList } from '@data/useMediaList'
import { useGalleryParamsContext } from '@lib/contexts/gallery-params-context'
import { Media as MediaType } from '@lib/types'
import FocusTrap from 'focus-trap-react'
import React, { useEffect } from 'react'
import { Link, Location, useLocation, useParams } from 'react-router-dom'
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

import styles from './ModalContainer.module.css'

interface Props {
  handleClose: () => void
  content: React.ReactNode
}

export const ModalContainer = ({ content, handleClose }: Props) => {
  const { galleryId, slug, mediaId } = useParams()
  const location = useLocation()
  const state = location.state as { backgroundLocation?: Location }

  const { sort, filter, orderBy, album } = useGalleryParamsContext()
  const { media, meta, loading, currentPage, setCurrentPage } = useMediaList(
    galleryId as string,
    sort,
    filter,
    orderBy,
    album
  )

  const currentIndex = media.findIndex((i) => i.id === mediaId)

  useEffect(() => {
    if (currentIndex + 5 > media.length && currentPage < meta.pageCount) {
      setCurrentPage(currentPage + 1)
    }
  }, [currentIndex])

  const closeModal = () => {
    handleClose()
  }

  const blockClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const buildImageLink = (
    imageList: MediaType[],
    index: number,
    direction: -1 | 1
  ): string | null => {
    const imageMeta = imageList[index + direction]
    return imageMeta ? `/gallery/${galleryId}/${slug}/${imageMeta.id}` : null
  }

  if (loading) {
    return <LoadingSpinner />
  }

  const isLastInLoadedList =
    media.length <= meta.total && currentIndex === media.length - 1

  const previousDisabled = currentIndex === 0
  const nextDisabled = isLastInLoadedList

  const prevLink = previousDisabled
    ? ''
    : buildImageLink(media, currentIndex, -1)
  const nextLink = nextDisabled ? '' : buildImageLink(media, currentIndex, +1)

  return (
    <FocusTrap>
      <div
        className={styles.modal}
        tabIndex={-1}
        onClick={(e) => {
          e.preventDefault()
          closeModal()
        }}
      >
        <div
          className={styles.contentWrapper}
          aria-label="Modal"
          aria-modal="true"
        >
          <div className={styles.close}>
            <button
              type="button"
              onClick={(e) => {
                e.stopPropagation()
                closeModal()
              }}
              id="modal-close"
            >
              <XMarkIcon width={24} height={24} color="#fff" />
            </button>
          </div>
          <div className={styles.previous}>
            {previousDisabled || !prevLink ? (
              <span onClick={blockClose}>
                <ChevronLeftIcon
                  title="Previous"
                  color="#fff"
                  width={36}
                  height={36}
                />
              </span>
            ) : (
              <Link
                to={prevLink}
                state={state}
                title="Previous"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <ChevronLeftIcon
                  title="Previous"
                  color="#fff"
                  width={36}
                  height={36}
                />
              </Link>
            )}
          </div>
          <div className={styles.next}>
            {nextDisabled || !nextLink ? (
              <span onClick={blockClose}>
                <ChevronRightIcon
                  title="Next"
                  color="#fff"
                  width={36}
                  height={36}
                />
              </span>
            ) : (
              <Link
                to={nextLink}
                state={state}
                title="Next"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                <ChevronRightIcon
                  title="Next"
                  color="#fff"
                  width={36}
                  height={36}
                />
              </Link>
            )}
          </div>
          <div
            className={styles.content}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {content}
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}
