import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { API_URL, VALID_EMAIL_REGEX } from '@lib/constants'
import { getErrorMessage } from '@lib/get-error-message'
import { Button } from '@components/Button'
import { Field } from '@components/Field'
import { FieldSet } from '@components/FieldSet'
import { Heading } from '@components/Heading'
import { RadioOption, RadioGroup } from '@components/RadioGroup'
import { Alert } from '@components/Alert'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { NotificationRegularity } from '@lib/types'
import styles from './EditProfileForm.module.css'
import { EditProfilePhoto } from '@components/EditProfilePhoto/EditProfilePhoto'

type BaseInputs = {
  firstName: string
  surname: string
  email: string
}

interface Inputs extends BaseInputs {
  newMediaEmail: NotificationRegularity
  newCommentEmail: NotificationRegularity
}

type Preferences = {
  newMediaEmail: NotificationRegularity
  newCommentEmail: NotificationRegularity
}

interface Payload extends BaseInputs {
  preferences: Preferences
  photoUrl?: string
}

interface NotificationOption extends RadioOption {
  value: NotificationRegularity
}

const NOTIFICATION_OPTIONS: NotificationOption[] = [
  { value: NotificationRegularity.daily, label: 'Daily' },
  { value: NotificationRegularity.weekly, label: 'Weekly' },
  { value: NotificationRegularity.none, label: 'Never' },
]

export const EditProfileForm = ({ onSuccess = () => {} }) => {
  const { user, updateUser } = useUserContext()
  const [isSaving, setIsSaving] = useState(false)
  const [saveError, setSaveError] = useState('')
  const [profilePhoto, setProfilePhoto] = useState<string | null>(null)
  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<Inputs>({
    defaultValues: {
      email: user?.email || undefined,
      firstName: user?.firstName || undefined,
      surname: user?.surname || undefined,
      newMediaEmail:
        user?.preferences?.newMediaEmail || NOTIFICATION_OPTIONS[1].value,
      newCommentEmail:
        user?.preferences?.newCommentEmail || NOTIFICATION_OPTIONS[1].value,
    },
  })

  useEffect(() => {
    if (user?.photoUrl) {
      setProfilePhoto(user.photoUrl)
    }
  }, [user])

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      setIsSaving(true)
      const { email, firstName, surname, newMediaEmail, newCommentEmail } = data
      const payload: Payload = {
        email,
        firstName,
        surname,
        preferences: {
          newMediaEmail,
          newCommentEmail,
        },
      }
      const ENDPOINT_URL = `${API_URL}/profiles/${user?.id}`
      const res = await fetchApiData(ENDPOINT_URL, {
        data: payload,
        method: 'PUT',
      })
      updateUser({
        email: res.email,
        firstName: res.firstName,
        surname: res.surname,
        preferences: {
          newCommentEmail,
          newMediaEmail,
        },
        photoUrl: profilePhoto,
      })
      setIsSaving(false)
      onSuccess()
    } catch (err) {
      setSaveError(getErrorMessage(err))
      setIsSaving(false)
    }
  }

  return !user ? (
    <LoadingSpinner />
  ) : (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {saveError && <Alert message={saveError} />}
      <FieldSet legendLabel="Your details" name="yourDetails">
        <div className="row">
          <Field
            inputId="firstName"
            inputType="text"
            label="First name"
            errorMessage={
              formErrors.firstName && 'Please enter your first name'
            }
            {...register('firstName', { required: true })}
          />
          <Field
            inputId="surname"
            inputType="text"
            label="Surname"
            errorMessage={formErrors.surname && 'Please enter your surname'}
            {...register('surname', { required: true })}
          />
        </div>
        <div className="row">
          <Field
            inputId="email"
            inputType="email"
            label="Email address"
            errorMessage={
              formErrors.email && 'Please enter a valid email address'
            }
            {...register('email', {
              required: true,
              pattern: {
                value: VALID_EMAIL_REGEX,
                message: 'Please enter a valid email',
              },
            })}
          />
        </div>
      </FieldSet>
      <EditProfilePhoto
        title="Profile Photo"
        description="We show this next to your name on comments and your uploads."
        savedPhoto={profilePhoto}
        setSavedPhoto={setProfilePhoto}
        folder="profile"
        deleteEndpoint={`${API_URL}/profiles/${user.id}/profile-photo`}
      />
      <div className={styles.notifications}>
        <Heading h2>Notifications</Heading>
        <div className="panel">
          <p className={styles.intro}>I'd like to know when...</p>
          <RadioGroup
            options={NOTIFICATION_OPTIONS}
            label="New photos are uploaded"
            {...register('newMediaEmail', { required: true })}
          />
          <RadioGroup
            options={NOTIFICATION_OPTIONS}
            label="People comment on my photos"
            {...register('newCommentEmail', { required: true })}
          />
        </div>
      </div>
      <Button
        isLoading={isSaving}
        label="Save &amp; Continue"
        actionLabel="Saving..."
      />
    </form>
  )
}
