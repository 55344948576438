export const loadCloudinaryScript = (callback: () => void) => {
  const existingScript = document.getElementById('cloudinaryWidgetScript')
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = 'https://upload-widget.cloudinary.com/global/all.js'
    script.id = 'cloudinaryWidgetScript'
    document.body.appendChild(script)
    script.onload = () => {
      if (callback) callback()
    }
  }
  if (existingScript && callback) callback()
}
