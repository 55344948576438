import { useParams } from 'react-router-dom'
import { Cloudinary } from '@cloudinary/url-gen'
import { CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import { Media } from '@lib/types'

import styles from './MediaCard.module.css'
import { MediaMetadata } from '@modules/gallery'
import { useState } from 'react'
import { Comments } from '@modules/gallery/detail/Comments'
import { Image as MediaImage } from '@components/Image'
import { Video } from '@components/Video'
import { Albums } from '@modules/gallery/detail/Albums'
import { useGalleryAlbums } from '@data/useGalleryAlbums'
import useMediaQuery from '@hooks/useMediaQuery'

const DEFAULT_NAMED_TRANSFORMATION = 't_media_lib_detail_auto_format'

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

interface MediaCardProps {
  data: Media
}

export const MediaCard = ({ data }: MediaCardProps) => {
  const { galleryId } = useParams()
  const isMobile = useMediaQuery('(hover: none) and (max-width: 1000px)')
  const { albums: availableAlbums } = useGalleryAlbums(galleryId as string)
  const [commentsOpen, setCommentsOpen] = useState(false)

  const mediaUrl = `${galleryId}/${data.id}`

  const handleCommentsOpenToggle = () => {
    setCommentsOpen(!commentsOpen)
  }

  return (
    <div className={styles.container}>
      <MediaMetadata inMediaCard metadata={data} />
      {data.type === 'image' && (
        <div className={styles.image}>
          <MediaImage
            cldInstance={cldInstance}
            cldTransformation={DEFAULT_NAMED_TRANSFORMATION}
            image={mediaUrl}
            alt=""
            width={data.width as number}
            height={data.height as number}
          />
        </div>
      )}
      {data.type === 'video' && (
        <div className={styles.image}>
          <Video cldInstance={cldInstance} video={mediaUrl} />
        </div>
      )}
      <footer>
        {availableAlbums.length > 0 && <Albums metadata={data} />}
        <div
          className={styles.commentsIndicator}
          onClick={handleCommentsOpenToggle}
        >
          {data.commentCount} Comments
        </div>
      </footer>
      {commentsOpen && <Comments mediaId={data.id} />}
    </div>
  )
}
