import classNames from 'classnames'
import React from 'react'
import styles from './Select.module.css'

export interface SelectOption {
  label: string
  value: string | number
}

export interface SelectProps {
  label: string
  labelPosition?: 'top' | 'left'
  inputId: string
  className?: string
  disabled?: boolean
  options: Array<SelectOption>
  includePleaseSelect?: boolean
  onChange: (ev: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string
  small?: boolean
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      label,
      labelPosition = 'top',
      className = '',
      inputId,
      includePleaseSelect = false,
      options,
      small = false,
      value,
      disabled,
      ...rest
    },
    ref
  ) => {
    const containerClasses = classNames(styles.container, className, {
      [styles.small]: small,
    })
    return (
      <div className={containerClasses}>
        <label
          htmlFor={inputId}
          className={styles[`position-${labelPosition}`]}
        >
          <span>{label}</span>
          <div className={styles.selectWrap}>
            <select
              id={inputId}
              ref={ref}
              value={value}
              disabled={disabled}
              {...rest}
              className={styles.select}
            >
              {includePleaseSelect && <option value="">Please select</option>}
              {options.map((opt) => (
                <option value={opt.value} key={opt.label}>
                  {opt.label}
                </option>
              ))}
            </select>
            <span className={styles.focus}></span>
          </div>
        </label>
      </div>
    )
  }
)
