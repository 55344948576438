import { Alert } from '@components/Alert'
import { Button } from '@components/Button'
import { Checkbox } from '@components/Checkbox'
import { Field } from '@components/Field'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { ResendLoginCode } from '@components/ResendLoginCode'
import { API_URL, PATHS } from '@lib/constants'
import { useUserContext } from '@lib/contexts/user-context'
import { fetchApiData } from '@lib/fetch-api-data'
import { getErrorMessage } from '@lib/get-error-message'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from '@hooks/useEffectOnce'
import classNames from 'classnames'

import styles from './Confirm.module.css'

type Inputs = {
  loginCode: string
  rememberMe: boolean
}

const Confirm = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const ac = searchParams.get('ac')
  const { user, updateUser } = useUserContext()
  const { register, handleSubmit } = useForm<Inputs>()
  const [checkingForEmail, setCheckingForEmail] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')
  const [showResendLink, setShowResendLink] = useState(false)

  useEffectOnce(() => {
    document.title = 'Sharetheirday - Confirm'
  })

  useEffect(() => {
    if (!user?.email) {
      navigate(PATHS.LOGIN)
    } else {
      setCheckingForEmail(false)
    }
  }, [user])

  useEffectOnce(() => {
    setTimeout(() => {
      setShowResendLink(true)
    }, 10000)
  })

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { loginCode, rememberMe } = data
    setIsLoading(true)

    const payload = {
      email: user?.email,
      code: loginCode,
      remember: rememberMe,
    }

    try {
      const userResult = await fetchApiData(`${API_URL}/auth/confirm`, {
        method: 'POST',
        data: payload,
      })

      updateUser({ ...userResult, isAuthenticated: true })

      if (ac) {
        navigate({ pathname: PATHS.GALLERY_JOIN, search: `?ac=${ac}` })
      } else if (user?.currentGallery) {
        navigate(PATHS.GALLERY_PAYMENT)
      } else if (location.state?.originalPath) {
        navigate(location.state.originalPath)
      } else {
        navigate(PATHS.ROOT, { state: { fromLogin: true } })
      }
    } catch (err) {
      setError(getErrorMessage(err))
      setIsLoading(false)
    }
  }

  const panelClasses = classNames('panel', styles.panel)

  return checkingForEmail ? (
    <LoadingSpinner color="#cfcfcf" />
  ) : (
    <div className="innerMain">
      <div className={panelClasses}>
        <p>We sent a message to</p>
        <p>
          <strong>{user?.email?.toLowerCase()}</strong>
        </p>
        <p>containing your login code.</p>
        <p className={styles.small}>This code expires in 5 minutes.</p>
      </div>
      <p
        className={styles.notReceivedMessage}
        style={{ opacity: showResendLink ? 1 : 0 }}
      >
        <ResendLoginCode />
      </p>

      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        {error && <Alert message={error} />}
        <div className="row">
          <Field
            inputId="loginCode"
            inputType="text"
            label="Login code"
            {...register('loginCode', { required: true })}
          />
        </div>
        <div>
          <Checkbox
            className="remember-me"
            inputId="rememberMe"
            label="Remember me"
            {...register('rememberMe', { required: false })}
          />
        </div>
        <Button
          label="Confirm and login"
          actionLabel="Processing"
          isLoading={isLoading}
          disabled={isLoading}
        />
      </form>
    </div>
  )
}

export default Confirm
