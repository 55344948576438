import { Outlet } from 'react-router-dom'
import { Sidebar } from '@components/Sidebar'
import { GalleryFooter } from '@components/GalleryFooter'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { loadCloudinaryScript } from '@lib/cloudinary-load-script'
import { useMoreMenuContext } from '@lib/contexts/more-menu-context'

import styles from './GalleryLayout.module.css'
import { useEffect, useState } from 'react'

export const GalleryLayout = () => {
  const { showMoreMenu, moreMenuShouldShow } = useMoreMenuContext()
  const [uploadScriptLoaded, setUploadScriptLoaded] = useState(false)

  useEffect(() => {
    loadCloudinaryScript(handleScriptLoaded)
  }, [])

  const handleScriptLoaded = () => {
    setUploadScriptLoaded(true)
  }

  return uploadScriptLoaded ? (
    <div className={styles.galleryWrapper}>
      <Sidebar />
      <main className={styles.main}>
        <Outlet />
      </main>
      {showMoreMenu && (
        <div
          className={styles.moreMenuShadow}
          onClick={() => {
            moreMenuShouldShow(false)
          }}
        />
      )}
      <GalleryFooter />
    </div>
  ) : (
    <LoadingSpinner />
  )
}
