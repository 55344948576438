import { useParams } from 'react-router-dom'
import { MediaList, MetaData } from '@modules/gallery'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { ErrorMessage } from '@components/ErrorMessage'
import { useEffect } from 'react'
import { useHandleGalleryError } from '@hooks/useHandleGalleryError'
import { useMediaList } from '@data/useMediaList'
import { useGalleryParamsContext } from '@lib/contexts/gallery-params-context'
import { useEffectOnce } from '@hooks/useEffectOnce'

const GalleryPage = () => {
  const { galleryId } = useParams()
  const { gallery, loading, error } = useGalleryMetadata(galleryId as string)
  const { sort, filter, orderBy, album } = useGalleryParamsContext()
  const { mutate } = useMediaList(
    galleryId as string,
    sort,
    filter,
    orderBy,
    album
  )

  useEffectOnce(() => {
    mutate()
  })

  useHandleGalleryError(galleryId)

  useEffect(() => {
    if (gallery) {
      document.title = `${gallery.name} Gallery - Sharetheirday`
    }
  }, [gallery])

  return (
    <>
      {error && <ErrorMessage>Error loading gallery data</ErrorMessage>}
      {loading && <LoadingSpinner />}
      {!error && gallery && (
        <>
          <MetaData showMessage />
          <MediaList />
        </>
      )}
    </>
  )
}

export default GalleryPage
