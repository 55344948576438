import { Heading } from '@components/Heading'

export const PhotoComments = () => {
  return (
    <>
      <section>
        <Heading h3>Who can see my comments?</Heading>
        <p>
          Anyone who has been invited to access the gallery, and has an account
          on Sharetheirday, can view comments on photos.
        </p>

        <p>
          We suggest you don&apos;t include personal information such as email
          addresses or phone numbers on comments.
        </p>
      </section>
    </>
  )
}
