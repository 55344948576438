import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { LinkButton } from '@components/LinkButton'
import { GalleryList } from '@modules/homepage'
import { Heading } from '@components/Heading'
import { API_URL } from '@lib/constants'
import { useGalleries } from '@data/useGalleries'
import { useUserContext } from '@lib/contexts/user-context'
import { trackEvent } from '@lib/tracking'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { LogoutButton } from '@components/LogoutButton'
import { LAUNCH_MODE } from '@lib/constants'
import { loadIntercomMessenger } from '@lib/support'
import styles from './Root.module.css'

const GALLERIES_ENDPOINT = `${API_URL}/galleries`

const Root = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { updateUser } = useUserContext()
  const { galleries, loading } = useGalleries(GALLERIES_ENDPOINT)

  loadIntercomMessenger()

  useEffectOnce(() => {
    document.title =
      'Sharetheirday - all the memories of your special day in one place'
  })

  useEffect(() => {
    if (galleries && galleries.length === 1 && location.state?.fromLogin) {
      const { id: gid, slug: gslug } = galleries[0]
      updateUser({ currentGallery: gid })
      trackEvent('View Gallery clicked', {
        gallery_id: gid,
        automatic_navigation: true,
      })
      navigate(`/gallery/${gid}/${gslug}`)
    }
  }, [galleries])

  return (
    <>
      <div className={styles.container}>
        {loading ? (
          <LoadingSpinner color="#666" />
        ) : (
          <>
            <Heading h1>Select Gallery</Heading>
            <div
              className={
                LAUNCH_MODE !== 'stealth' ? styles.panel : styles.stealthpanel
              }
            >
              {LAUNCH_MODE !== 'stealth' && (
                <LinkButton href="/gallery/new" label="Create a Gallery" />
              )}
              <LinkButton href="/gallery/join" label="Join a Gallery" />
            </div>
            <GalleryList />
            <LogoutButton listPage />
          </>
        )}
      </div>
    </>
  )
}

export default Root
