import { Heading } from '@components/Heading'
import { UploadButton } from '@components/UploadButton'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { Link, useParams } from 'react-router-dom'
import styles from './EmptyGallery.module.css'

export const EmptyGallery: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { galleryId, slug } = useParams()
  const { gallery } = useGalleryMetadata(galleryId as string)

  const galleryLink = `/gallery/${galleryId}/${slug}`

  if (gallery?.isHost) {
    return (
      <div className={styles.emptyGalleryContainer}>
        <Heading h1>Welcome to your gallery!</Heading>
        <p>
          You can add a gallery profile photo, a welcome message, and choose
          whether to show the social sharing banner on the{' '}
          <Link to={`${galleryLink}/settings`}>gallery settings page.</Link>
        </p>
        <p>
          When you're ready, you can also{' '}
          <Link to={`${galleryLink}/invite-guests`}>invite your guests</Link> to
          take part and upload their photos.
        </p>
        <p>
          And while you're getting ready and inviting guests, why not{' '}
          <Link to={`${galleryLink}/albums`}>create some albums</Link> and
          upload some photos of your engagement or of you as a couple?
        </p>
        <p>
          <UploadButton />
        </p>
      </div>
    )
  }

  return (
    <div className={styles.emptyGalleryContainer}>
      <Heading h1>Share the love!</Heading>
      <p>
        No photos uploaded yet - why not upload some awesome memories from the
        special day?
      </p>
      <p>
        <UploadButton />
      </p>
    </div>
  )
}
