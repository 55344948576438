import * as React from 'react'
import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'

const imgSizes = [256, 512, 768, 1024, 1280]

export interface ImageProps {
  cldInstance: Cloudinary
  cldTransformation: string
  image: string
  alt?: string
  width: number
  height: number
}

/**
 * Returns Cloudinary image URL with srcset and sizes
 */
export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
  ({ cldInstance, cldTransformation, image, width, height, ...rest }, ref) => {
    const srcSetArr: string[] = []

    const defaultSrc = `${cldInstance
      .image(image)
      .namedTransformation(
        cldTransformation as unknown as NamedTransformationAction
      )
      .toURL()}`

    imgSizes.map((imgSize) => {
      const transformation =
        `${cldTransformation}_${imgSize}` as unknown as NamedTransformationAction
      srcSetArr.push(
        `${cldInstance
          .image(image)
          .namedTransformation(transformation)
          .toURL()}`.concat(`${' '}${imgSize}w`)
      )
    })

    const srcSet = srcSetArr.join(',')

    return (
      <img
        ref={ref}
        srcSet={srcSet}
        sizes={'(min-width: 50em) 50em, 100vw'}
        src={defaultSrc}
        style={{ aspectRatio: `${width} / ${height}` }}
        {...rest}
      />
    )
  }
)
