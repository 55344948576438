import { Heading } from '@components/Heading'
import { PaymentOptions, PaymentSummary } from '@modules/gallery/payment'
import { useEffect, useState } from 'react'
import { useUserContext } from '@lib/contexts/user-context'
import { Progress } from '@components/Progress'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { fetchApiData } from '@lib/fetch-api-data'
import { API_URL } from '@lib/constants'
import { useNavigate } from 'react-router-dom'
import { Button } from '@components/Button'

import styles from './Payment.module.css'

const ENDPOINT = `${API_URL}/payments/voucher`

const GalleryPayment = () => {
  const navigate = useNavigate()
  const { updateUser, user } = useUserContext()
  const [checkingVoucher, setCheckingVoucher] = useState(true)
  const [hasValidVoucher, setHasValidVoucher] = useState(false)

  const checkVoucherValidity = async () => {
    if (!window.localStorage || !window.localStorage.getItem('svc')) {
      setCheckingVoucher(false)
      return
    }

    try {
      const code = window.localStorage.getItem('svc')
      await fetchApiData(ENDPOINT, {
        method: 'POST',
        data: { code, galleryId: user?.currentGallery },
      })
      window.localStorage.removeItem('svc')
      setHasValidVoucher(true)
      setCheckingVoucher(false)
    } catch (err) {
      console.error(err)
      setCheckingVoucher(false)
    }
  }

  useEffectOnce(() => {
    document.title = `Sharetheirday - Payment details`
    checkVoucherValidity()
  })

  useEffect(() => {
    if (user && !user.isCreatingGallery) {
      updateUser({
        ...user,
        isCreatingGallery: true,
      })
    }
  }, [user, updateUser])

  const handleContinue = () => {
    navigate('/gallery/confirmation')
  }

  return (
    <>
      <Progress currentPage={2} />

      {checkingVoucher && <LoadingSpinner color="#999" />}

      {!checkingVoucher && (
        <>
          {hasValidVoucher && (
            <>
              <Heading h1>Voucher accepted</Heading>
              <p>
                Thank you for redeeming your gift voucher. <br />
                This has now been applied to your gallery.
              </p>
              <div className={styles.buttonContainer}>
                <Button
                  type="button"
                  label="Continue"
                  onClick={handleContinue}
                />
              </div>
            </>
          )}

          {!hasValidVoucher && (
            <>
              <Heading h1>Payment details</Heading>
              <PaymentSummary />
              <Heading h2>Payment method</Heading>
              <div className="innerMain">
                <PaymentOptions />
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default GalleryPayment
