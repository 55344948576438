import { Heading } from '@components/Heading'
import { Link } from 'react-router-dom'

import styles from './404.module.css'

export const FourOhFour = () => {
  return (
    <div className={styles.container}>
      <img src="/img/error.svg" alt="" />
      <Heading h1>Page not found</Heading>
      <p>
        Whoops we couldn't find that page! <br />
        Please try again, or <Link to="/">go to the homepage</Link>.
      </p>
    </div>
  )
}
