import { MediaComment } from '@lib/types'
import useSWR from 'swr'

export function useComments(url: string | null) {
  const { data, mutate, error } = useSWR<MediaComment[]>(url)

  const loading = !data && !error

  return {
    error,
    loading,
    comments: data,
    mutate,
  }
}
