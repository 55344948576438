import { Heading } from '@components/Heading'

import styles from './NoActivity.module.css'

export const NoActivity = () => {
  return (
    <div className={styles.container}>
      <img src="/img/activity.svg" alt="" />
      <Heading h2>No recent activity...</Heading>
      <p>No new photos, videos, or comments have been added to the gallery.</p>
    </div>
  )
}
