import { Heading } from '@components/Heading'

import styles from './EmptyGallery.module.css'
import { UploadButton } from '@components/UploadButton'

export const EmptyAlbum = () => {
  return (
    <div className={styles.emptyGalleryContainer}>
      <Heading h1>Share the love!</Heading>
      <p>
        No photos added to this album yet - why not explore all the photos and
        add some to this album? Or maybe upload some more memories of the
        special day?
      </p>

      <p>
        <UploadButton />
      </p>
    </div>
  )
}
