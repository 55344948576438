import { useGalleryAlbums } from '@data/useGalleryAlbums'
import { useParams } from 'react-router-dom'
import styles from './Albums.module.css'
import { useRef, useState, Fragment } from 'react'
import { API_URL } from '@lib/constants'
import { fetchApiData } from '@lib/fetch-api-data'
import { Media } from '@lib/types'
import { FolderOpenIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import useGlobalMutation from '@hooks/useGlobalMutation'

interface AlbumsProps {
  metadata: Media
}

export const Albums = ({ metadata }: AlbumsProps) => {
  const mutate = useGlobalMutation()
  const listRef = useRef(null)
  const { galleryId } = useParams()
  const { albums: availableAlbums } = useGalleryAlbums(galleryId as string)
  const [showList, setShowList] = useState(false)

  const handleShowList = () => {
    setShowList((s) => !s)
  }

  const handleAdd = async (albumId: string, albumName: string) => {
    try {
      const ADD_MEDIA_TO_ALBUM_ENDPOINT = `${API_URL}/galleries/${galleryId}/albums/${albumId}`
      await fetchApiData(ADD_MEDIA_TO_ALBUM_ENDPOINT, {
        method: 'PUT',
        data: {
          mediaId: metadata.id,
        },
      })

      mutate(
        (key) =>
          typeof key === 'string' &&
          new RegExp(`/media/${metadata.id}$`).test(key),
        {
          ...metadata,
          albums: [...metadata.albums, { id: albumId, name: albumName }],
        },
        { revalidate: false }
      )

      mutate(
        (key) => typeof key === 'string' && key.includes('/media?'),
        (currentData: any) => {
          if (Array.isArray(currentData)) {
            return currentData.map((cd) => ({
              ...cd,
              data: cd.data.map((m: any) => ({
                ...m,
                albums: [...m.albums, { id: albumId, name: albumName }],
              })),
            }))
          } else {
            return {
              ...currentData,
              data: currentData.data.map((m: any) => ({
                ...m,
                albums: [...m.albums, { id: albumId, name: albumName }],
              })),
            }
          }
        },
        { revalidate: false }
      )
    } catch (err) {
      console.error(err)
    }
  }

  const handleRemove = async (albumId: string) => {
    try {
      const REMOVE_MEDIA_FROM_ALBUM_ENDPOINT = `${API_URL}/galleries/${galleryId}/albums/${albumId}/media/${metadata.id}`
      await fetchApiData(REMOVE_MEDIA_FROM_ALBUM_ENDPOINT, {
        method: 'DELETE',
      })

      mutate(
        (key) =>
          typeof key === 'string' &&
          new RegExp(`/media/${metadata.id}$`).test(key),
        {
          ...metadata,
          albums: [...metadata.albums.filter((a) => a.id !== albumId)],
        },
        { revalidate: false }
      )

      mutate(
        (key) => typeof key === 'string' && key.includes('/media?'),
        (currentData: any) => {
          if (Array.isArray(currentData)) {
            return currentData.map((cd) => ({
              ...cd,
              data: cd.data.map((m: any) => ({
                ...m,
                albums: [...m.albums.filter((a: any) => a.id !== albumId)],
              })),
            }))
          } else {
            return {
              ...currentData,
              data: currentData.data.map((m: any) => ({
                ...m,
                albums: [...m.albums.filter((a: any) => a.id !== albumId)],
              })),
            }
          }
        },
        { revalidate: false }
      )
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className={styles.container}>
      <button
        className={styles.addButton}
        type="button"
        onClick={handleShowList}
        title="Select albums"
      >
        <FolderOpenIcon width={20} height={20} />

        <span className={styles.selectedList}>
          {metadata.albums.length > 0 &&
            metadata.albums.map((a, idx) => (
              <Fragment key={a.id}>
                {idx ? ', ' : ''}
                {a.name}
              </Fragment>
            ))}
        </span>
      </button>
      {showList && (
        <div className={styles.albumsListHolder} ref={listRef}>
          <ul className={styles.albumsList}>
            {availableAlbums.map((a) => {
              const selected = !!metadata.albums.find((sa) => sa.id === a.id)
              return (
                <li key={a.id}>
                  <button
                    onClick={() =>
                      selected ? handleRemove(a.id) : handleAdd(a.id, a.name)
                    }
                    className={selected ? styles.selected : styles.ready}
                  >
                    <span className={styles.selectedIcon}>
                      {selected ? (
                        <CheckCircleIcon width={16} height={16} />
                      ) : (
                        ''
                      )}
                    </span>
                    {a.name}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}
