import { API_URL } from '@lib/constants'
import { AlbumsList } from '@lib/types'
import useSWR from 'swr'

export function useGalleryAlbums(galleryId: string | null) {
  const ENDPOINT = `${API_URL}/galleries/${galleryId}/albums`

  const { data, mutate, error } = useSWR<AlbumsList>(
    galleryId ? ENDPOINT : null
  )

  const loading = !data && !error

  return {
    error,
    loading,
    albums: data?.data || [],
    mutate,
  }
}
