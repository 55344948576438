import { useNavigate } from 'react-router-dom'
import { Heading } from '@components/Heading'
import { EditProfileForm } from '@modules/profile/EditProfileForm'

import styles from './New.module.css'
import { useEffectOnce } from '@hooks/useEffectOnce'

const NewProfile = () => {
  const navigate = useNavigate()

  useEffectOnce(() => {
    document.title = `Sharetheirday - Create Your Profile`
  })

  const onSuccess = () => {
    return navigate('/')
  }

  return (
    <div className={styles.container}>
      <Heading h1>Create your profile</Heading>
      <p className={styles.intro}>
        As this is your first visit, please let us know a little about you.
      </p>
      <EditProfileForm onSuccess={onSuccess} />
    </div>
  )
}

export default NewProfile
