import { Heading } from '@components/Heading'

export const UploadingPhotos = () => {
  return (
    <>
      <section>
        <Heading h3>How do I upload photos and videos?</Heading>
        <p>
          On every gallery page, there&apos;s an Upload button in the top right
          corner (or in the footer menu on mobile).
        </p>
        <ul>
          <li>
            Clicking or tapping on that button will open a panel that allows you
            to select which photos you want to upload.
          </li>

          <li>
            If you&apos;re on a desktop computer you can simply drag photos on
            to the panel, or click the Select button.
          </li>

          <li>
            On mobile you can click the Select button, and that will allow you
            to choose to either take a new photo to upload, or choose from your
            photo library on your device.
          </li>
          <li>
            When you&apos;ve selected the photos you want to upload - and
            remember to choose your best ones - then the panel will show
            thumbnails of them, with the progress of each photo upload.
          </li>

          <li>
            Once they&apos;re all uploaded, you will be able to click the Done
            button and return to the gallery.
          </li>
        </ul>

        <p>
          If you&apos;re viewing the gallery list, you may notice a Processing
          tile where your photos will eventually appear once they&apos;re ready
          to view.
        </p>
      </section>
      <section>
        <Heading h3>What do I do when I have problems uploading?</Heading>
        <p>
          If you&apos;re having problems or issues when uploading photos, then
          in the first case, please refresh your web browser and try again.
        </p>

        <p>
          If you still can&apos;t upload photos or videos, then please reach out
          to our team through the Contact Us page in the Help Center.
        </p>
      </section>
      <section>
        <Heading h3>
          Can I upload my professional photographer's photos?
        </Heading>
        <p>
          We know how much you&apos;ll want to share the awesome photos that
          your professional photographer has taken of your special day.
        </p>

        <p>
          Every professional photographer treats the sharing of their photos
          differently, so the best thing to do is check in with them, and make
          sure they&apos;re happy you&apos;re uploading them to your gallery.
        </p>

        <p>
          Sometimes they&apos;re happy as long as you keep any watermarks
          visible.
        </p>
      </section>
    </>
  )
}
