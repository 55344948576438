import { Heading } from '@components/Heading'

export const ViewingPhotos = () => {
  return (
    <>
      <section>
        <Heading h3>How do I view original size photos?</Heading>
        <p>
          Sometimes you just want to see a full size photo to appreciate its
          splendour, or maybe you just want to print out a keepsake.
        </p>
        <ul>
          <li>
            When you&apos;re viewing a single photo page, click on the three
            dots menu next to the name of the original uploader.
          </li>

          <li>
            Then click the View Original Size Photo link, which will open a new
            view with the original photo in it.
          </li>

          <li>
            On Desktop computers you can right click and save the original
            photo, and on mobile you can hold your finger on the photo and
            select Save To Photos.
          </li>
        </ul>
      </section>
      <section>
        <Heading h3>How do I report an inappropriate photo?</Heading>
        <p>
          Occasionally guests will upload or videos that some consider to be
          inappropriate.
        </p>

        <ul>
          <li>
            You can let our team know about these by clicking on the three dots
            menu next to the name of the original uploader on the photo detail
            page.
          </li>
          <li>
            This will open a panel that allows you to select what you feel is
            inappropriate about the photo, and any comments you want to add.
          </li>

          <li>
            When you click Send Report, it immediately emails the team here at
            Sharetheirday, and one of us will take a look as soon as possible.
          </li>

          <li>
            If you&apos;ve selected a sensitive category for the report,
            we&apos;ll temporarily hide the photo from the gallery until one of
            our team has confirmed the content.
          </li>
        </ul>
        <p>
          When we&apos;ve decided what to do with the photo, we&apos;ll contact
          you and let you know the result. In certain cases we&apos;ll also
          contact the original uploader to let them know their photo has been
          taken down.
        </p>
      </section>
    </>
  )
}
