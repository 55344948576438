import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { useEffect } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router-dom'

export const HostOnly = () => {
  const navigate = useNavigate()
  const { galleryId } = useParams()
  const { gallery, loading } = useGalleryMetadata(galleryId as string)

  useEffect(() => {
    if (gallery && !gallery.isHost) {
      navigate(`/gallery/${gallery.id}/${gallery.slug}`)
    }
  }, [gallery])

  return <Outlet />
}
