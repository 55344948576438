import { ChangeEvent, useState } from 'react'
import classNames from 'classnames'
import styles from './EditableAlbumName.module.css'
import { fetchApiData } from '@lib/fetch-api-data'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { API_URL } from '@lib/constants'

interface EditableAlbumNameProps {
  name: string
  id: string
  galleryId: string
  onEdited: () => void
}

export const EditableAlbumName = ({
  name,
  id,
  galleryId,
  onEdited,
}: EditableAlbumNameProps) => {
  const [isEditing, setIsEditing] = useState(false)
  const [albumName, setAlbumName] = useState(name)
  const handleEdit = () => {
    setIsEditing(true)
  }

  const handleCancel = () => {
    setAlbumName(name)
    setIsEditing(false)
  }

  const handleSave = async () => {
    try {
      const data = {
        galleryId,
        name: albumName,
      }

      const UPDATE_ALBUM_ENDPOINT = `${API_URL}/albums/${id}`
      await fetchApiData(UPDATE_ALBUM_ENDPOINT, {
        method: 'PUT',
        data,
      })

      setIsEditing(false)
      onEdited()
    } catch (err) {
      console.error('There was an error saving the new name')
      setAlbumName(name)
      setIsEditing(false)
    }
  }

  const handleEditTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAlbumName(e.target.value)
  }

  const editingClasses = classNames(styles.leftAligned, styles.editing)

  return (
    <>
      {isEditing && (
        <div className={editingClasses}>
          <span className={styles.leftAligned}>
            <input
              type="text"
              value={albumName}
              onChange={handleEditTextChange}
              className={styles.field}
            />
          </span>
          <button type="button" onClick={handleSave}>
            Save
          </button>
          <button
            type="button"
            className={styles.cancel}
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      )}
      {!isEditing && (
        <>
          <span className={styles.leftAligned}>{albumName}</span>
          <span className={styles.editButton}>
            <button title="Edit" onClick={handleEdit} type="button">
              <PencilSquareIcon
                width={20}
                height={20}
                className="colorize-independence"
              />
            </button>
          </span>
        </>
      )}
    </>
  )
}
