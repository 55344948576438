import { User } from '@lib/types/api'
import * as React from 'react'
import { clearUser, updateUser } from './user-context-actions'

interface UserContextState {
  user: User | null
}

interface UserContextActions {
  updateUser: (user: Partial<User>) => void
  clearUser: () => void
}

export type UserContextType = UserContextState & UserContextActions

export const defaultUserContextValue: UserContextType = {
  user: null,
  updateUser: () => {},
  clearUser: () => {},
}

export const UserContext = React.createContext<UserContextType>(
  defaultUserContextValue
)

export const UserContextProvider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [userState, setUserState] = React.useState<User | null>(null)

  const userContextValue: UserContextType = {
    user: userState,
    updateUser: updateUser(userState, setUserState),
    clearUser: clearUser(setUserState),
  }

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  )
}

export const useUserContext = () =>
  React.useContext<UserContextType>(UserContext)
