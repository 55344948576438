import { HeaderLogo } from '@components/HeaderLogo'
import { GalleryNav } from '@components/GalleryNav'
import { ProfileNav } from '@components/ProfileNav'
import { useLocation } from 'react-router-dom'

import styles from './Sidebar.module.css'
import { GalleryChangePanel } from './GalleryChangePanel'
import { LogoutButton } from '@components/LogoutButton'

export const Sidebar = () => {
  const location = useLocation()

  return (
    <header className={styles.header}>
      <HeaderLogo />
      <ProfileNav galleryUrl={location.pathname} />
      <GalleryNav />
      <GalleryChangePanel />
      <div className={styles.logoutButtonWrap}>
        <LogoutButton />
      </div>
    </header>
  )
}
