import { Cloudinary } from '@cloudinary/url-gen'
import { NamedTransformationAction } from '@cloudinary/url-gen/actions/namedTransformation/NamedTransformationAction'
import { CLOUDINARY_CLOUD_NAME } from '@lib/constants'
import classNames from 'classnames'
import { UserCircleIcon } from '@heroicons/react/24/outline'

import styles from './Headshot.module.css'

const DEFAULT_NAMED_TRANSFORMATION =
  't_gallery_profile' as unknown as NamedTransformationAction

const cldInstance = new Cloudinary({
  cloud: { cloudName: CLOUDINARY_CLOUD_NAME },
})

interface HeadshotProps {
  height?: number
  width?: number
  additionalClassnames?: string
  photoId?: string | null
}

export const Headshot = ({
  height = 36,
  width = 36,
  additionalClassnames,
  photoId,
}: HeadshotProps) => {
  const defaultHeadshotClasses = classNames(
    additionalClassnames,
    styles.defaultHeadshot
  )

  const profilePhotoURL = photoId
    ? `${cldInstance
        .image(`profile/${photoId}`)
        .namedTransformation(DEFAULT_NAMED_TRANSFORMATION)
        .toURL()}`
    : null

  return profilePhotoURL ? (
    <div
      className={styles.headshot}
      style={{ width: `${width}px`, height: `${height}px` }}
    >
      <img src={profilePhotoURL} alt="" />
    </div>
  ) : (
    <UserCircleIcon
      className={defaultHeadshotClasses}
      width={width}
      height={height}
    />
  )
}
