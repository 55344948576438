import { ApiErrorBody, FetchError } from '@lib/types'
import { FunctionComponent, PropsWithChildren } from 'react'
import { SWRConfig } from 'swr'

export const prepareFetcherError = (body: ApiErrorBody) => {
  const error: FetchError = new Error(body.message)
  error.info = body
  error.status = body.statusCode
  return error
}

export const fetcher = async (resource: string, init: RequestInit) => {
  const res = await fetch(resource, { ...init, credentials: 'include' })

  if (!res.ok) {
    const body: ApiErrorBody = await res.json()
    const error = prepareFetcherError(body)
    throw error
  }

  return res.json()
}

export const SWRConfigProvider: FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  return (
    <SWRConfig
      value={{
        fetcher,
      }}
    >
      {children}
    </SWRConfig>
  )
}
