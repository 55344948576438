import { Heading } from '@components/Heading'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { JoinGalleryForm } from '@modules/gallery'
import styles from './Join.module.css'

const JoinGallery = () => {
  useEffectOnce(() => {
    document.title = `Sharetheirday - Join a Gallery`
  })

  return (
    <div className="innerMain">
      <div className={styles.wrapper}>
        <Heading h1>Welcome Guest!</Heading>
        <p>Enter the code from the poster or invite card.</p>
        <JoinGalleryForm />
      </div>
    </div>
  )
}

export default JoinGallery
