import classNames from 'classnames'
import styles from './NotificationsCount.module.css'

interface Props {
  count: number
  isSideBar?: boolean
}

export const NotificationsCount = ({ count, isSideBar }: Props) => {
  const notificationStyles = classNames(styles.notificationsCount, {
    [styles.sidebarCount]: isSideBar,
  })
  return (
    <span className={notificationStyles}>{count > 99 ? '99+' : count}</span>
  )
}
