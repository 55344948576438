import { User } from '@lib/types/api'
import { Dispatch, SetStateAction } from 'react'

export const updateUser = (
  userState: User | null,
  setUserState: Dispatch<SetStateAction<User | null>>
) => {
  return (userData: Partial<User>) => {
    // if (!userState) return null

    const user = Object.assign({}, userState, userData)
    setUserState(user)
  }
}

export const clearUser = (
  setUserState: Dispatch<SetStateAction<User | null>>
) => {
  return () => {
    setUserState(null)
  }
}
