import { Heading } from '@components/Heading'
import { Info } from '@components/Info'
import { LegalLinks } from '@components/LegalLinks'
import { Progress } from '@components/Progress'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { CreateGalleryForm } from '@modules/gallery/new'
import { useLocation, useNavigate } from 'react-router-dom'
import { LAUNCH_MODE } from '@lib/constants'
import { useState } from 'react'
import { LoadingSpinner } from '@components/LoadingSpinner'

const NewGallery = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [checkingLaunchMode, setCheckingLaunchMode] = useState(true)

  const state = location.state

  useEffectOnce(() => {
    document.title = `Sharetheirday - New Gallery`
    if (LAUNCH_MODE === 'stealth' && (!state || !state.voucher)) {
      navigate('/')
    } else {
      setCheckingLaunchMode(false)
    }
  })

  return (
    <>
      <Progress currentPage={1} />

      <Heading h1>Your Gallery</Heading>

      {state && state.voucher && <Info message="Voucher applied! Thank you!" />}

      {checkingLaunchMode ? (
        <LoadingSpinner color="#999" />
      ) : (
        <div className="innerMain">
          <CreateGalleryForm />
        </div>
      )}
      <LegalLinks actionText="creating a gallery on" />
    </>
  )
}

export default NewGallery
