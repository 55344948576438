import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useForm, SubmitHandler } from 'react-hook-form'
import { Field } from '@components/Field'
import { Button } from '@components/Button'

import styles from './AddAlbum.module.css'
import { fetchApiData } from '@lib/fetch-api-data'
import { API_URL } from '@lib/constants'
import { useGalleryAlbums } from '@data/useGalleryAlbums'

interface Inputs {
  name: string
}

export const AddAlbum = () => {
  const { galleryId } = useParams()
  const { mutate } = useGalleryAlbums(galleryId as string)
  const {
    register,
    handleSubmit,
    formState: { isValid },
    reset,
  } = useForm<Inputs>({ mode: 'onChange' })
  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsLoading(true)
    try {
      await fetchApiData(`${API_URL}/albums`, {
        method: 'POST',
        data: { name: data.name, galleryId },
      })
      mutate()
      setIsLoading(false)
      reset()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <Field
          inputId="name"
          inputType="text"
          label="Album Name"
          {...register('name', { required: true })}
        />
        <span style={{ width: '200px' }}>
          <Button
            label="Add album"
            actionLabel="Processing"
            isLoading={isLoading}
            disabled={isLoading || !isValid}
          />
        </span>
      </form>
    </div>
  )
}
