export const APP_ENVIRONMENT = import.meta.env.VITE_APP_ENVIRONMENT || 'local'
export const LAUNCH_MODE = import.meta.env.VITE_LAUNCH_MODE || 'live'
export const API_URL = import.meta.env.VITE_API_URL || 'http://localhost:3000'
export const APP_URL = import.meta.env.VITE_APP_URL || 'notset'
export const STRIPE_KEY =
  import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || 'notset'
export const PAYPAL_KEY = import.meta.env.VITE_PAYPAL_CLIENT_ID || 'notset'
export const CLOUDINARY_CLOUD_NAME =
  import.meta.env.VITE_CLOUDINARY_CLOUD_NAME || 'notset'
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN || 'notset'
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN || 'notset'
export const INTERCOM_API_BASE =
  import.meta.env.VITE_INTERCOM_API_BASE || 'notset'
export const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID || 'notset'

export const VALID_EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export enum PATHS {
  ROOT = '/',
  LOGIN = '/auth/login',
  LOGIN_CONFIRM = '/auth/confirm',
  PROFILE = '/profile/new',
  GALLERY_JOIN = '/gallery/join',
  GALLERY_PAYMENT = '/gallery/payment',
}

export const DEFAULT_PAGE_SIZE = 24
export const DEFAULT_META = {
  total: 0,
  hasNext: false,
  pageCount: 0,
}

export const INTERCOM_CUSTOM_LAUNCHER_ID = 'launch_intercom'
