import { useParams } from 'react-router-dom'
import { LoadingSpinner } from '@components/LoadingSpinner'
import { useGalleryMetadata } from '@data/useGalleryMetadata'
import { ErrorMessage } from '@components/ErrorMessage'
import { Heading } from '@components/Heading'
import { HelpCenter } from '@modules/gallery/help'
import { useEffect } from 'react'
import { useEffectOnce } from '@hooks/useEffectOnce'
import { trackEvent } from '@lib/tracking'
import { useHandleGalleryError } from '@hooks/useHandleGalleryError'

const Help = () => {
  const { galleryId } = useParams()
  const { gallery, loading, error } = useGalleryMetadata(galleryId as string)

  useHandleGalleryError(galleryId)

  useEffect(() => {
    if (gallery) {
      document.title = `Help Center - ${gallery?.name} Gallery - Sharetheirday`
    }
  }, [gallery])

  useEffectOnce(() => {
    if (galleryId) {
      trackEvent('Help Center page view', {
        gallery_id: galleryId,
      })
    }
  })

  return (
    <div className="innerMain">
      {error && <ErrorMessage>Error loading gallery data</ErrorMessage>}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Heading h1>How can we help?</Heading>
          <HelpCenter />
        </>
      )}
    </div>
  )
}

export default Help
